import React from 'react';
import { ConfigurationClient } from './common/ConfigurationClient';
import { ecuDatapointRequestCreator } from './common/DatapointRequestCreator';
import { extractInputValue, extractSelectValue } from './common/InputUtils';
import Button from './components/Button';
import Select from './components/Select';
import TabbedView from './components/TabbedView';
import Table from './components/Table';
import TitledView from './components/TitledView';
import { canIds, Datapoint, EcuDatapointAttributes, ECU, ecus, NewEcuDatapoint, TransmissionMethod, transmissionMethods, Unit, units } from './model/Datapoint';
import { axiosResponseEcuDatapointMarshaller } from './common/Marshaller';

import './DatapointView.css';
import { FailureFlashMessage, SuccessFlashMessage } from './components/FlashMessage';
import { FlashMessagesProducer, withFlashMessages } from './common/FlashMessagesContext';
import { setForFadeDuration } from './common/ReactUtils';
import { styleGenerator } from './common/TableStyles';
import { COLOR_FADE_DELAY, COLOR_FADE_DURATION } from './common/Constants';

const getEmptyNewEcuDatapoint = (): NewEcuDatapoint => {
    return {
        version: 'v1.0',
        type: 'ECU',
        name: '',
        description: '',
        rate: 0,
        transmissionMethod: 'CANBus',
        rangeMin: 0,
        rangeMax: 0,
        units: undefined,
        canId: Number('0x400'),
        ecu: 'ecu0',
        msb: 0,
        length: 0,
        gain: 0,
    }
}

const validateDatapointAttributes = <T extends EcuDatapointAttributes>(dp: T): { valid: boolean, errors: string[] } => {
    let valid = true;
    const errors: string[] = [];
    const setInvalid = (error: string) => {
        valid = false;
        errors.push(error);
    }
    if (!dp.name) {
        setInvalid('Datapoint must have a name');
    }
    if (!dp.description) {
        setInvalid('Datapoint must have a description');
    }
    if (isNaN(dp.rate)) {
        setInvalid('Datapoint must have a numeric rate');
    }
    if (isNaN(dp.rangeMin)) {
        setInvalid('Datapoint must have a numeric range min');
    }
    if (isNaN(dp.rangeMax)) {
        setInvalid('Datapoint must have a numeric range max');
    }
    if (isNaN(dp.msb)) {
        setInvalid('Datapoint must have a numeric msb');
    }
    if (isNaN(dp.length)) {
        setInvalid('Datapoint must have a numeric length');
    }
    if (isNaN(dp.gain)) {
        setInvalid('Datapoint must have a numeric gain');
    }
    return { valid, errors };
}

type EcuDatapointsProps = FlashMessagesProducer & {
    configurationClient: ConfigurationClient;
}

const EcuDatapoints: React.FC<EcuDatapointsProps > = ( {configurationClient, addFlashMessage} ) => {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [datapoints, setDatapoints] = React.useState<Datapoint<EcuDatapointAttributes>[]>([]);
    const [newEcuDatapoint, setNewEcuDatapoint] = React.useState<NewEcuDatapoint>(getEmptyNewEcuDatapoint());
    const [createRequested, setCreateRequested] = React.useState<boolean>(false);
    const [deleteId, setDeleteId] = React.useState<string>();
    const [updateId, setUpdateId] = React.useState<string>();
    const [successfulId, setSuccessfulId] = React.useState<string>();
    const [failureId, setFailureId] = React.useState<string>();

    React.useEffect(() => {
        if (loading) {
            const getDatapoints = async () => {
                const dps = await configurationClient.getDatapoints(axiosResponseEcuDatapointMarshaller);
                setDatapoints(dps);
                setLoading(false);
            };
            getDatapoints();
        }
        if (deleteId) {
            const deleteDatapoint = async () => {
                setDeleteId(undefined);
                try {
                    const delId = deleteId;
                    await configurationClient.deleteDatapoint(delId);
                    addFlashMessage((<SuccessFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Datapoint deleted</SuccessFlashMessage>));
                    setForFadeDuration(setSuccessfulId, deleteId);
                    setLoading(true);
                } catch (e: any) {
                    addFlashMessage((<FailureFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Delete did not succeed. Try refreshing the page and retrying</FailureFlashMessage>));
                    setForFadeDuration(setFailureId, deleteId);
                }
            }
            deleteDatapoint();
        }
        if (updateId) {
            const updateDatapoint = async () => {
                setUpdateId(undefined);
                const dp = datapoints.find(s => s.id === updateId);
                if (dp) {
                    const validation = validateDatapointAttributes(dp.attributes)
                    if (validation.valid) {
                        try {
                            await configurationClient.updateDatapoint(dp, ecuDatapointRequestCreator, axiosResponseEcuDatapointMarshaller);
                            addFlashMessage((<SuccessFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Datapoint updated</SuccessFlashMessage>));
                            setForFadeDuration(setSuccessfulId, updateId);
                            setLoading(true);
                        } catch (e: any) {
                            addFlashMessage((<FailureFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Update did not succeed. Try refreshing the page and retrying</FailureFlashMessage>));
                            setForFadeDuration(setFailureId, updateId);
                        }
                    } else {
                        addFlashMessage((<FailureFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >{validation.errors.map(e => <div>{e}</div>)}</FailureFlashMessage>));
                        setForFadeDuration(setFailureId, updateId);
                    }
                } else {
                    throw new Error(`Subscriber with id: ${updateId} not found`);
                }
            };
            updateDatapoint();
        }
        if (createRequested) {
            const createSub = async () => {
                setCreateRequested(false)
                if (newEcuDatapoint) {
                    const validation = validateDatapointAttributes(newEcuDatapoint);
                    if (validation.valid) {
                        try {
                            const newDp = await configurationClient.createDatapoint(newEcuDatapoint, ecuDatapointRequestCreator, axiosResponseEcuDatapointMarshaller);
                            addFlashMessage((<SuccessFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION}>Datapoint created</SuccessFlashMessage>));
                            setForFadeDuration(setSuccessfulId, newDp.id);
                            setLoading(true);
                            setNewEcuDatapoint(getEmptyNewEcuDatapoint());
                        } catch (e: any) {
                            addFlashMessage((<FailureFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Create did not succeed. Try again</FailureFlashMessage>));
                        }
                    } else {
                        addFlashMessage((<FailureFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >{validation.errors.map(e => <div>{e}</div>)}</FailureFlashMessage>));
                    }
                } else {
                    throw new Error('Something went wrong');
                }
            };
            createSub();
        }
    }, [loading, updateId, deleteId, createRequested]);

    const onChangeUpdateFactory = <T,>(id: string, valueExtractor: (val: any) => T, callback: (d: Datapoint<EcuDatapointAttributes>, input: T) => void) => {
        return (value: any) => {
            const datapoint = datapoints.find(s => s.id === id);
            if (datapoint) {
                const val = valueExtractor(value);
                callback(datapoint, val);
                setDatapoints([...datapoints]);
            } else {
                throw new Error(`Datapoint with ${id} was not found`);
            }
        };
    };

    const extractUnit = (input: any): Unit => extractSelectValue(input) as Unit;
    const extractTransmissionMethod = (input: any): TransmissionMethod => extractSelectValue(input) as TransmissionMethod;
    const extractCanId = (input: any): Number => Number(extractSelectValue(input));
    const extractEcuId = (input: any): ECU => extractSelectValue(input) as ECU;

    const onChangeUpdateDatapointName  = (id: string) => onChangeUpdateFactory(id, extractInputValue, (datapoint, v) => datapoint.attributes.name = v);
    const onChangeUpdateDatapointDescription  = (id: string) => onChangeUpdateFactory(id, extractInputValue, (datapoint, v) => datapoint.attributes.description = v);
    const onChangeUpdateDatapointRate  = (id: string) => onChangeUpdateFactory(id, extractInputValue, (datapoint, v) => datapoint.attributes.rate = Number.parseFloat(v));
    const onChangeUpdateDatapointRangeMin = (id: string) => onChangeUpdateFactory(id, extractInputValue, (datapoint, v) => datapoint.attributes.rangeMin = Number.parseFloat(v));
    const onChangeUpdateDatapointRangeMax = (id: string) => onChangeUpdateFactory(id, extractInputValue, (datapoint, v) => datapoint.attributes.rangeMax = Number.parseFloat(v));
    const onChangeUpdateDatapointUnits = (id: string) => onChangeUpdateFactory(id, extractUnit, (datapoint, v) => datapoint.attributes.units = v);
    const onChangeUpdateDatapointTransmissionMethod = (id: string) => onChangeUpdateFactory(id, extractTransmissionMethod, (datapoint, v) => datapoint.attributes.transmissionMethod = v);

    const onChangeUpdateDatapointCanId = (id: string) => onChangeUpdateFactory(id, extractCanId, (datapoint, v) => datapoint.attributes.canId = v);
    const onChangeUpdateDatapointEcu = (id: string) => onChangeUpdateFactory(id, extractEcuId, (datapoint, v) => datapoint.attributes.ecu = v);
    const onChangeUpdateDatapointMsb = (id: string) => onChangeUpdateFactory(id, extractInputValue, (datapoint, v) => datapoint.attributes.msb = Number.parseFloat(v));
    const onChangeUpdateDatapointLength = (id: string) => onChangeUpdateFactory(id, extractInputValue, (datapoint, v) => datapoint.attributes.length = Number.parseFloat(v));
    const onChangeUpdateDatapointGain = (id: string) => onChangeUpdateFactory(id, extractInputValue, (datapoint, v) => datapoint.attributes.gain = Number.parseFloat(v));

    const onChangeCreateFactory = <T,>(valueExtractor: (val: any) => T, callback: (input: T) => void) => {
        return (value: any) => {
            const val = valueExtractor(value);
            callback(val);
            setNewEcuDatapoint({...newEcuDatapoint});
        }
    };

    const onChangeCreateDatapointName = onChangeCreateFactory(extractInputValue, (v) => newEcuDatapoint.name = v);
    const onChangeCreateDatapointDescription = onChangeCreateFactory(extractInputValue, (v) => newEcuDatapoint.description = v);
    const onChangeCreateDatapointRate = onChangeCreateFactory(extractInputValue, (v) => newEcuDatapoint.rate = Number.parseFloat(v));
    const onChangeCreateDatapointRangeMin = onChangeCreateFactory(extractInputValue, (v) => newEcuDatapoint.rangeMin = Number.parseFloat(v));
    const onChangeCreateDatapointRangeMax = onChangeCreateFactory(extractInputValue, (v) => newEcuDatapoint.rangeMax = Number.parseFloat(v));
    const onChangeCreateDatapointUnits = onChangeCreateFactory(extractUnit, (v) => newEcuDatapoint.units = v);
    const onChangeCreateDatapointTransmissionMethod = onChangeCreateFactory(extractTransmissionMethod, (v) => newEcuDatapoint.transmissionMethod = v);
    const onChangeCreateDatapointCanId = onChangeCreateFactory(extractCanId, (v) => newEcuDatapoint.canId = v);
    const onChangeCreateDatapointEcuId = onChangeCreateFactory(extractEcuId, (v) => newEcuDatapoint.ecu = v);
    const onChangeCreateDatapointMsb = onChangeCreateFactory(extractSelectValue, (v) => newEcuDatapoint.msb = Number.parseFloat(v));
    const onChangeCreateDatapointLength = onChangeCreateFactory(extractSelectValue, (v) => newEcuDatapoint.length = Number.parseFloat(v));
    const onChangeCreateDatapointGain = onChangeCreateFactory(extractSelectValue, (v) => newEcuDatapoint.gain = Number.parseFloat(v));

    const onClickButtonFactoryCreator = (id: string, func: (id: string) => void) => {
        return () => {
            func(id);
        }
    }

    const canIdOption = (v: string) => {
        return {
            label: v,
            value: Number('0' + v),
        };
    };
    const identityOption = (v: string) => { return { value: v, label: v }; };
    const onUpdateFactory = (id: string) => onClickButtonFactoryCreator(id, setUpdateId);
    const onDeleteFactory = (id: string) => onClickButtonFactoryCreator(id, setDeleteId);
    const onCreate = () => setCreateRequested(true);
    const sanitizeNaN = (input: number) => isNaN(input) ? '' : input;

    const columnDefs = [
        {
            id: 'datapoint-table-name',
            classNamePrefix: 'datapoint-table-name',
            keyGen: (d: Datapoint<EcuDatapointAttributes>) => 'name',
            header: 'Name',
            cell: (d: Datapoint<EcuDatapointAttributes>) => <input value={d.attributes.name} disabled={true} className='datapoint-table-name-input' placeholder='Enter name' onChange={onChangeUpdateDatapointName(d.id)} />,
            createRowCell: () => <input value={newEcuDatapoint.name} className='datapoint-table-name-input' placeholder='Enter name' onChange={onChangeCreateDatapointName} />,
            filterPredicate: (d: Datapoint<EcuDatapointAttributes>, filterText: string) => d.attributes.name.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
        },
        {
            id: 'datapoint-table-description',
            classNamePrefix: 'datapoint-table-description',
            keyGen: (d: Datapoint<EcuDatapointAttributes>) => 'description',
            header: 'Description',
            cell: (d: Datapoint<EcuDatapointAttributes>) => <input value={d.attributes.description} className='datapoint-table-description-input' placeholder='Enter description' onChange={onChangeUpdateDatapointDescription(d.id)} />,
            createRowCell: () => <input value={newEcuDatapoint.description} className='datapoint-table-description-input' placeholder='Enter description' onChange={onChangeCreateDatapointDescription} />,
            filterPredicate: (d: Datapoint<EcuDatapointAttributes>, filterText: string) => d.attributes.description.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
        },
        {
            id: 'datapoint-table-rate',
            classNamePrefix: 'datapoint-table-rate',
            keyGen: (d: Datapoint<EcuDatapointAttributes>) => 'rate',
            header: 'Rate',
            cell: (d: Datapoint<EcuDatapointAttributes>) => <input type='text' value={sanitizeNaN(d.attributes.rate)} className='datapoint-table-rate-input' placeholder='Enter rate' onChange={onChangeUpdateDatapointRate(d.id)} />,
            createRowCell: () => <input value={sanitizeNaN(newEcuDatapoint.rate)} className='datapoint-table-rate-input' placeholder='Enter rate' onChange={onChangeCreateDatapointRate} />
        },
        {
            id: 'datapoint-table-range-min',
            classNamePrefix: 'datapoint-table-range-min',
            keyGen: (d: Datapoint<EcuDatapointAttributes>) => 'range-min',
            header: 'Range Min',
            cell: (d: Datapoint<EcuDatapointAttributes>) => <input type='text' value={sanitizeNaN(d.attributes.rangeMin)} className='datapoint-table-range-min-input' placeholder='Enter range min' onChange={onChangeUpdateDatapointRangeMin(d.id)} />,
            createRowCell: () => <input value={sanitizeNaN(newEcuDatapoint.rangeMin)} className='datapoint-table-range-min-input' placeholder='Enter range min' onChange={onChangeCreateDatapointRangeMin} />
        },
        {
            id: 'datapoint-table-range-max',
            classNamePrefix: 'datapoint-table-range-max',
            keyGen: (d: Datapoint<EcuDatapointAttributes>) => 'range-max',
            header: 'Range Max',
            cell: (d: Datapoint<EcuDatapointAttributes>) => <input type='text' value={sanitizeNaN(d.attributes.rangeMax)} className='datapoint-table-range-max-input' placeholder='Enter range max' onChange={onChangeUpdateDatapointRangeMax(d.id)} />,
            createRowCell: () => <input value={sanitizeNaN(newEcuDatapoint.rangeMax)} className='datapoint-table-range-max-input' placeholder='Enter range max' onChange={onChangeCreateDatapointRangeMax} />
        },
        {
            id: 'datapoint-table-msb',
            classNamePrefix: 'datapoint-table-msb',
            keyGen: (d: Datapoint<EcuDatapointAttributes>) => 'msb',
            header: 'MSB',
            cell: (d: Datapoint<EcuDatapointAttributes>) => <input type='text' value={sanitizeNaN(d.attributes.msb)} className='datapoint-table-msb-input' placeholder='Enter msb' onChange={onChangeUpdateDatapointMsb(d.id)} />,
            createRowCell: () => <input value={sanitizeNaN(newEcuDatapoint.msb)} className='datapoint-table-msb-input' placeholder='Enter msb' onChange={onChangeCreateDatapointMsb} />
        },
        {
            id: 'datapoint-table-length',
            classNamePrefix: 'datapoint-table-length',
            keyGen: (d: Datapoint<EcuDatapointAttributes>) => 'length',
            header: 'Length',
            cell: (d: Datapoint<EcuDatapointAttributes>) => <input type='text' value={sanitizeNaN(d.attributes.length)} className='datapoint-table-length-input' placeholder='Enter length' onChange={onChangeUpdateDatapointLength(d.id)} />,
            createRowCell: () => <input value={sanitizeNaN(newEcuDatapoint.length)} className='datapoint-table-length-input' placeholder='Enter length' onChange={onChangeCreateDatapointLength} />
        },
        {
            id: 'datapoint-table-gain',
            classNamePrefix: 'datapoint-table-gain',
            keyGen: (d: Datapoint<EcuDatapointAttributes>) => 'gain',
            header: 'Gain',
            cell: (d: Datapoint<EcuDatapointAttributes>) => <input type='text' value={sanitizeNaN(d.attributes.gain)} className='datapoint-table-gain-input' placeholder='Enter gain' onChange={onChangeUpdateDatapointGain(d.id)} />,
            createRowCell: () => <input value={sanitizeNaN(newEcuDatapoint.gain)} className='datapoint-table-gain-input' placeholder='Enter gain' onChange={onChangeCreateDatapointGain} />
        },
        {
            id: 'datapoint-table-units',
            classNamePrefix: 'datapoint-table-units',
            keyGen: (d: Datapoint<EcuDatapointAttributes>) => 'units',
            header: 'Units',
            cell: (d: Datapoint<EcuDatapointAttributes>) => <Select items={units.map(u => u)} selectedValue={d.attributes.units} option={identityOption} onChange={onChangeUpdateDatapointUnits(d.id)} />,
            createRowCell: () => <Select selectedValue={newEcuDatapoint.units} items={units.map(u => u)} placeholder='Select' option={identityOption} onChange={onChangeCreateDatapointUnits} />,
            filterPredicate: (d: Datapoint<EcuDatapointAttributes>, filterText: string) => d.attributes.units?.toLocaleLowerCase().includes(filterText?.toLocaleLowerCase()) || false
        },
        {
            id: 'datapoint-table-can-id',
            classNamePrefix: 'datapoint-table-can-id',
            keyGen: (d: Datapoint<EcuDatapointAttributes>) => 'can-id',
            header: 'Can ID',
            cell: (d: Datapoint<EcuDatapointAttributes>) => <Select items={canIds.map(u => u)} selectedValue={d.attributes.canId} option={canIdOption} onChange={onChangeUpdateDatapointCanId(d.id)} />,
            createRowCell: () => <Select selectedValue={newEcuDatapoint.canId} items={canIds.map(u => u)} placeholder='Select' option={canIdOption} onChange={onChangeCreateDatapointCanId} />,
            filterPredicate: (d: Datapoint<EcuDatapointAttributes>, filterText: string) => ('0x' + d.attributes.canId.toString(16)).includes(filterText?.toLocaleLowerCase())
        },
        {
            id: 'datapoint-table-transmission-method',
            classNamePrefix: 'datapoint-table-transmission-method',
            keyGen: (d: Datapoint<EcuDatapointAttributes>) => 'transmission-method',
            header: 'Transmission Method',
            cell: (d: Datapoint<EcuDatapointAttributes>) => <Select items={transmissionMethods.map(u => u)} selectedValue={d.attributes.transmissionMethod} option={identityOption} onChange={onChangeUpdateDatapointTransmissionMethod(d.id)} />,
            createRowCell: () => <Select selectedValue={newEcuDatapoint.transmissionMethod} items={transmissionMethods.map(u => u)} placeholder='Select' option={identityOption} onChange={onChangeCreateDatapointTransmissionMethod} />
        },
        {
            id: 'datapoint-table-ecu',
            classNamePrefix: 'datapoint-table-ecu',
            keyGen: (d: Datapoint<EcuDatapointAttributes>) => 'ecu',
            header: 'Ecu',
            cell: (d: Datapoint<EcuDatapointAttributes>) => <Select items={ecus.map(u => u)} selectedValue={d.attributes.ecu} option={identityOption} onChange={onChangeUpdateDatapointEcu(d.id)} />,
            createRowCell: () => <Select selectedValue={newEcuDatapoint.ecu} items={ecus.map(u => u)} placeholder='Select' option={identityOption} onChange={onChangeCreateDatapointEcuId} />,
            filterPredicate: (d: Datapoint<EcuDatapointAttributes>, filterText: string) => d.attributes.ecu.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
        },
        {
            id: 'datapoint-table-action',
            classNamePrefix: 'datapoint-table-action',
            keyGen: (d: Datapoint<EcuDatapointAttributes>) => 'action',
            header: '',
            cell: (d: Datapoint<EcuDatapointAttributes>) => <div className='buttons-container'><div className='delete-button-container'><Button className='delete-button' onClick={onDeleteFactory(d.id)}>Delete</Button></div><div className='update-button-container'><Button className='update-button' onClick={onUpdateFactory(d.id)}>Update</Button></div></div>,
            createRowCell: () => <div><div className='create-button-container'><Button className="create-button" onClick={onCreate} >Create</Button></div></div>
        },
    ];
    const style = styleGenerator(successfulId, failureId);
    return (
        <Table loading={loading} includeCreateRow={true} header={<div><strong>Datapoints</strong></div>} keyGen={d => d.id} columnDefs={columnDefs} items={datapoints} pageSize={10} style={style} />
    );
};

type DatapointViewProps = FlashMessagesProducer & {
    configurationClient: ConfigurationClient;
}

const DatapointView: React.FC<DatapointViewProps> = ( {configurationClient, addFlashMessage} ) => {
    return (
        <TitledView title="Datapoints">
            <div className="datapoints-page">
                <TabbedView toggleButtonClassName='datapoints-toggle-button' views={[
                    {title: 'ECU', label: 'ECU', view: <EcuDatapoints configurationClient={configurationClient} addFlashMessage={addFlashMessage} />},
                    ]} />
            </div>
        </TitledView>
    );
}

export default withFlashMessages(DatapointView);
