import React from "react";
import { extractInputValue } from './common/InputUtils';
import { ConfigurationClient } from "./common/ConfigurationClient";
import Button from "./components/Button";
import ClearFloat from "./components/ClearFloat";
import TabbedView from "./components/TabbedView";
import Table from "./components/Table";
import TitledView from "./components/TitledView";
import { EcuSourceAttributes, NewVehicleSource, Source, VehicleSourceAttributes } from "./model/Source";

import './SourceView.css';
import Badge from "./components/Badge";
import { axiosResponseEcuSourceMarshaller } from "./common/Marshaller";
import { ecuSourceRequestCreator } from "./common/SourceRequestCreator";
import { FailureFlashMessage, SuccessFlashMessage } from "./components/FlashMessage";
import { FlashMessagesProducer, withFlashMessages } from "./common/FlashMessagesContext";
import { styleGenerator } from "./common/TableStyles";
import { setForFadeDuration } from "./common/ReactUtils";
import { COLOR_FADE_DELAY, COLOR_FADE_DURATION } from './common/Constants';

const getEmptyNewSource = (): NewVehicleSource => {
    return {
        version: '',
        name: '',
        description: '',
        datapointTags: new Map<string, string[]>([['ecu', ['ecu0', 'ecu1', 'ecu2', 'ecu3', 'ecumux']]]),
        vehicleId: ''
    };
}

const validateSource = (source: NewVehicleSource | EcuSourceAttributes): { valid: boolean, errors: string[] } => {
    let valid = true;
    const errors: string[] = [];
    const setInvalid = (error: string) => {
        valid = false;
        errors.push(error);
    }
    if (!source.name) {
        setInvalid('Source must have a name');
    }
    if (!source.description) {
        setInvalid('Source must have a description');
    }
    if (!source.vehicleId) {
        setInvalid('Source must have a vehicle Id');
    }
    /*if (source.datapointTags.size === 0) {
        setInvalid('Source must have at least one datapoint tag');
    }*/
    return { valid, errors };
}



type EcuVehicleProps = FlashMessagesProducer & {
    configurationClient: ConfigurationClient
}

const EcuVehicles: React.FunctionComponent<EcuVehicleProps> = ( {configurationClient, addFlashMessage} ) => {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [sources, setSources] = React.useState<Source<VehicleSourceAttributes>[]>([]);
    const [newSource, setNewSource] = React.useState<NewVehicleSource>(getEmptyNewSource());
    const [createRequested, setCreateRequested] = React.useState<boolean>(false);
    const [deleteId, setDeleteId] = React.useState<string>();
    const [updateId, setUpdateId] = React.useState<string>();
    const [successfulId, setSuccessfulId] = React.useState<string>();
    const [failureId, setFailureId] = React.useState<string>();

    const shallowCopyNewSource = (): void => {
        setNewSource({
            ...newSource
        });
    }

    React.useEffect(() => {
        if (loading) {
            const getSources = async () => {
                const fetchedSources = await configurationClient.getSources(axiosResponseEcuSourceMarshaller);
                setLoading(false);
                setSources(fetchedSources);
            }
            getSources();
        }

        if (deleteId) {
            const deleteSource = async () => {
                setDeleteId(undefined);
                try {
                    await configurationClient.deleteSource(deleteId);
                    addFlashMessage((<SuccessFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Source deleted</SuccessFlashMessage>));
                    setLoading(true);
                } catch (e: any) {
                    addFlashMessage((<FailureFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Delete did not succeed. Try refreshing the page and retrying</FailureFlashMessage>));
                    setForFadeDuration(setFailureId, deleteId);
                }
            }
            deleteSource();
        }
        if (updateId) {
            const updateSource = async () => {
                setUpdateId(undefined);
                const source = sources.find(s => s.id === updateId);
                if (source) {
                    const validation = validateSource(source.attributes);
                    if (validation.valid) {
                        try {
                            await configurationClient.updateSource(source, ecuSourceRequestCreator, axiosResponseEcuSourceMarshaller);
                            addFlashMessage((<SuccessFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Source updated</SuccessFlashMessage>));
                            setForFadeDuration(setSuccessfulId, updateId);
                            setLoading(true);
                        } catch (e: any) {
                            addFlashMessage((<FailureFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Update did not succeed. Try refreshing the page and retrying</FailureFlashMessage>));
                            setForFadeDuration(setFailureId, updateId);
                        }
                    } else {
                        addFlashMessage((<FailureFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >{validation.errors.map(e => <div>{e}</div>)}</FailureFlashMessage>));
                        setForFadeDuration(setFailureId, updateId);
                    }
                } else {
                    throw new Error(`Source with id: ${updateId} not found`);
                }
            }
            updateSource();
        }
        if (createRequested) {
            const createSource = async () => {
                setCreateRequested(false)
                if (newSource) {
                    const validation = validateSource(newSource);
                    if (validation.valid) {
                        try {
                            const justCreateSource = await configurationClient.createSource(newSource, ecuSourceRequestCreator, axiosResponseEcuSourceMarshaller);
                            addFlashMessage((<SuccessFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Source updated</SuccessFlashMessage>));
                            setForFadeDuration(setSuccessfulId, justCreateSource.id);
                            setLoading(true);
                            setNewSource(getEmptyNewSource());
                        } catch (e: any) {
                            addFlashMessage((<FailureFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Create did not succeed. Try again</FailureFlashMessage>));
                        }
                    } else {
                        addFlashMessage((<FailureFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >{validation.errors.map(e => <div>{e}</div>)}</FailureFlashMessage>));
                    }
                } else {
                    throw new Error('Something went wrong');
                }
            }
            createSource();
        }
    }, [loading, updateId, deleteId, createRequested]);

    const onCreate = async () => {
        setCreateRequested(true);
    }

    const onUpdateFactory = (id: string) => {
        return async () => {
            setUpdateId(id);
        }
    }

    const onDeleteFactory = (id: string) => {
        return async () => {
            setDeleteId(id);
        }
    }

    const onCancel = () => {
        setNewSource(getEmptyNewSource());
        setLoading(true);
    };

    const onChangeUpdateFactory = <T,>(id: string, valueExtractor: (val: any) => T, callback: (s: Source<VehicleSourceAttributes>, input: T) => void) => {
        return (value: T) => {
            const source = sources.find(s => s.id === id);
            if (source) {
                const val = valueExtractor(value);
                callback(source, val);
                setSources([...sources]);
            } else {
                throw new Error(`Source with ${id} was not found`);
            }
        };
    };

    const onChangeUpdateName = (id: string) => onChangeUpdateFactory(id, extractInputValue, (s, v) => s.attributes.name = v);
    const onChangeUpdateDescription = (id: string) => onChangeUpdateFactory(id, extractInputValue, (s, v) => s.attributes.description = v);
    const onChangeUpdateVehicleId = (id: string) => onChangeUpdateFactory(id, extractInputValue, (s, v) => s.attributes.vehicleId = v);

    const onUpdateTagEditorFactory = (id: string) => {
        return (k: string, v: string[]) => {
            const source = sources.find(s => s.id === id);
            if (source) {
                source.attributes.datapointTags.set(k, v);
                setSources(sources.map(s => {
                    return {
                        ...s,
                        datapointTags: new Map<string, string[]>(s.attributes.datapointTags)
                    }
                }));
            } else {
                throw new Error(`Source with id: ${id} not found`);
            }
        };
    };
    const rowAddFactory = onUpdateTagEditorFactory;
    const onDeleteTagEditorFactory = (id: string) => {
        return (k: string) => {
            const source = sources.find(s => s.id === id);
            if (source) {
                source.attributes.datapointTags.delete(k);
                setSources(sources.map(s => {
                    return {
                        ...s,
                        datapointTags: new Map<string, string[]>(s.attributes.datapointTags)
                    }
                }));
            } else {
                throw new Error(`Source with id: ${id} not found`);
            }
        }
    };
    const rowDeleteFactory = onDeleteTagEditorFactory;

    const onCloseTagEditorFactory = (id: string) => {
        return (updatedTags: Map<string, string[]>) => {
            const source = sources.find(s => s.id === id);
            if (source) {
                source.attributes.datapointTags = updatedTags;
                setSources(sources.map(s => {
                    return {
                        ...s,
                        datapointTags: new Map<string, string[]>(s.attributes.datapointTags)
                    }
                }));
            } else {
                throw new Error(`Source with id: ${id} not found`);
            }
        };
    };

    const onChangeCreateFactory = <T,>(valueExtractor: (val: any) => T, callback: (input: T) => void) => {
        return (value: T) => {
            const val = valueExtractor(value);
            callback(val);
            setNewSource({...newSource});
        }
    };

    const onChangeCreateName = onChangeCreateFactory(extractInputValue, (v) => newSource.name = v);
    const onChangeCreateDescription = onChangeCreateFactory(extractInputValue, (v) => newSource.description = v);
    const onChangeCreateVehicleId = onChangeCreateFactory(extractInputValue, (v) => newSource.vehicleId = v);

    const onUpdateTagEditorCreate = (k: string, v: string[]) => {
        if (newSource) {
            newSource.datapointTags.set(k, v);
            shallowCopyNewSource();
        }
    };
    const onDeleteTagEditorCreate = (k: string) => {
        if (newSource) {
            newSource.datapointTags?.delete(k);
            shallowCopyNewSource();
        }
    }
    const rowAddCreate = onUpdateTagEditorCreate;
    const rowDeleteCreate = onDeleteTagEditorCreate;
    const onCloseTagEditorCreate = (updatedTags: Map<string, string[]>) => {
        if (newSource) {
            newSource.datapointTags = updatedTags;
        }
    };

    const getNewSourceDatapointTagBadges = (): JSX.Element[] => {
        const datapointTags = newSource.datapointTags;
        const badges: JSX.Element[] = [];
        for (const key of Array.from(datapointTags.keys())) {
            badges.push(<Badge key={key} >{key}</Badge>);
        }
        return badges;
    }

    const getSourceDatapointTagBadges = (s: Source<VehicleSourceAttributes>): JSX.Element[] => {
        const datapointTags = s.attributes.datapointTags;
        const badges: JSX.Element[] = [];
        for (const key of Array.from(datapointTags.keys())) {
            //badges.push(<Badge key={key} >{key} : {datapointTags.get(key)?.join(',')}</Badge>);
            badges.push(<Badge key={key} >{key}</Badge>);
        }
        return badges;
    }

    const columnDefs = [
        {
            id: 'source-table-name',
            classNamePrefix: 'source-table-name',
            keyGen: (s: Source<VehicleSourceAttributes>) => 'name',
            header: 'Name',
            cell: (s: Source<VehicleSourceAttributes>) => <input value={s.attributes.name} className='source-table-name-input' placeholder='Name' onChange={onChangeUpdateName(s.id)} />,
            createRowCell: () => <input value={newSource.name} className='source-table-name-input' placeholder='Name' onChange={onChangeCreateName} />,
            filterPredicate: (s: Source<VehicleSourceAttributes>, filterText: string) => s.attributes.name.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
        },
        {
            id: 'source-table-description',
            classNamePrefix: 'source-table-description',
            keyGen: (s: Source<VehicleSourceAttributes>) => 'description',
            header: 'Description',
            cell: (s: Source<VehicleSourceAttributes>) => <input value={s.attributes.description} className='source-table-description-input' placeholder='Description' onChange={onChangeUpdateDescription(s.id)} />,
            createRowCell: () => <input value={newSource.description} className='source-table-description-input' placeholder='Description' onChange={onChangeCreateDescription} />,
            filterPredicate: (s: Source<VehicleSourceAttributes>, filterText: string) => s.attributes.description.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
        },
        {
            id: 'source-table-vehicle-id',
            classNamePrefix: 'source-table-vehicle-id',
            keyGen: (s: Source<VehicleSourceAttributes>) => 'vehicle-id',
            header: 'Vehicle Id',
            cell: (s: Source<VehicleSourceAttributes>) => <input value={s.attributes.vehicleId} className='source-table-vehicle-id-input' placeholder="Enter vehicle id" onChange={onChangeUpdateVehicleId(s.id)} />,
            createRowCell: () => <input value={newSource.vehicleId} className='source-table-vehicle-id-input' placeholder="Enter vehicle id" onChange={onChangeCreateVehicleId} />,
            filterPredicate: (s: Source<VehicleSourceAttributes>, filterText: string) => s.attributes.vehicleId.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
        },
        {
            id: 'source-table-datapoint-tags',
            classNamePrefix: 'source-table-datapoint-tags',
            keyGen: (s: Source<VehicleSourceAttributes>) => 'datapoint-tags',
            header: 'Datapoint Tags',
            cell: (s: Source<VehicleSourceAttributes>) => <div className="datapoint-tags-container">{getSourceDatapointTagBadges(s)}{/*<TagEditor tags={s.attributes.datapointTags} onUpdate={onUpdateTagEditorFactory(s.id)} rowDelete={rowDeleteFactory(s.id)} rowAdd={rowAddFactory(s.id)} onCloseEditor={onCloseTagEditorFactory(s.id)} />*/}</div>,
            createRowCell: () => <div className="datapoint-tags-container">{getNewSourceDatapointTagBadges()}</div>, //<div className="datapoint-tags-container">{getNewSourceDatapointTagBadges()}<TagEditor tags={newSource.datapointTags} onUpdate={onUpdateTagEditorCreate} rowDelete={rowDeleteCreate} rowAdd={rowAddCreate} onCloseEditor={onCloseTagEditorCreate} /></div>
            filterPredicate: (s: Source<VehicleSourceAttributes>, filterText: string) => Array.from(s.attributes.datapointTags.keys()).some(k =>  k.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())) /* || Array.from(s.attributes.datapointTags.values()).some(vals =>  vals.some(v => v.toLocaleLowerCase().includes(filterText.toLocaleLowerCase()))) */
        },
        {
            id: 'source-table-action',
            classNamePrefix: 'source-table-action',
            keyGen: (s: Source<VehicleSourceAttributes>) => 'action',
            header: '',
            cell: (s: Source<VehicleSourceAttributes>) => <div className="buttons-container"><div className="delete-button-container"><Button className="delete-button" onClick={onDeleteFactory(s.id)}>Delete</Button></div><div className="update-button-container"><Button className="update-button" onClick={onUpdateFactory(s.id)}>Update</Button></div></div>,
            createRowCell: () => <div className="create-button-container"><Button className="create-button" onClick={onCreate} >Create</Button></div>
        },
    ];

    const style = styleGenerator(successfulId, failureId);

    return (
        <div>
            <Table loading={loading} includeCreateRow={true} header={null} keyGen={s => s.id} style={style} columnDefs={columnDefs} items={sources} pageSize={25} />
            <div className='control-box'>
                <Button className='cancel-button' onClick={onCancel}>Cancel</Button>
            </div>
            <ClearFloat />
        </div>
    );
}

type SourceViewProps = FlashMessagesProducer & {
    configurationClient: ConfigurationClient
}

const SourceView: React.FunctionComponent<SourceViewProps> = ({ configurationClient, addFlashMessage }) => {
    return (
        <TitledView title="Sources">
            <div className="source-page">
                <TabbedView toggleButtonClassName='source-toggle-button' views={[
                    {title: 'ecu-vehicle', label: 'ECU', view: <EcuVehicles configurationClient={configurationClient} addFlashMessage={addFlashMessage} />},
                    ]} />
            </div>
        </TitledView>
    );
}

export default withFlashMessages(SourceView);
