import { Datapoint, DatapointAttributes } from "../model/Datapoint";
import { Source, SourceAttributes } from "../model/Source";
import Subscriber, { NewSubscriber } from "../model/Subscriber";
import { NewSubscription, Subscription } from "../model/Subscription";
import { DatapointClient } from "./DatapointClient";
import { Marshaller } from "./Marshaller";
import { DatapointRequestCreator } from "./DatapointRequestCreator";
import { SourceClient } from "./SourceClient";
import { SubscriberClient } from "./SubscriberClient";
import { SubscriptionClient } from "./SubscriptionClient";
import { UserClient } from "./UserClient";
import { SourceRequestCreator } from "./SourceRequestCreator";


export class ConfigurationClient {
    private readonly datapointClient;
    private readonly sourceClient;
    private readonly subscriberClient;
    private readonly subscriptionClient;
    private readonly userClient;
    constructor(
        datapointClient: DatapointClient,
        sourceClient: SourceClient,
        subscriberClient: SubscriberClient,
        subscriptionClient: SubscriptionClient,
        userClient: UserClient,
    ) {
        this.datapointClient = datapointClient;
        this.sourceClient = sourceClient;
        this.subscriberClient = subscriberClient;
        this.subscriptionClient = subscriptionClient;
        this.userClient = userClient;
    }

    public async getDatapoints<T extends DatapointAttributes>(marshaller: Marshaller<any, Datapoint<T>>, filter?: (i: any) => boolean): Promise<Datapoint<T>[]> {
        return this.datapointClient.getDatapoints(marshaller, filter);
    }

    public async updateDatapoint<T extends DatapointAttributes>(datapoint: Datapoint<T>, requestCreator: DatapointRequestCreator<T>, marshaller: Marshaller<any, Datapoint<T>>): Promise<Datapoint<any>> {
        return this.datapointClient.updateDatapoint(datapoint, requestCreator, marshaller);
    }

    public async createDatapoint<T extends DatapointAttributes>(datapoint: T, requestCreator: DatapointRequestCreator<T>, marshaller: Marshaller<any, Datapoint<T>>): Promise<Datapoint<T>> {
        return this.datapointClient.createDatapoint(datapoint, requestCreator, marshaller);
    }

    public async deleteDatapoint(datapointId: string): Promise<boolean> {
        return this.datapointClient.deleteDatapoint(datapointId);
    }

    public async getSources<T extends SourceAttributes>(marshaller: Marshaller<any, Source<T>>, filter?: (i: any) => boolean): Promise<Source<T>[]> {
        return this.sourceClient.getSources(marshaller, filter);
    }

    public async createSource<T extends SourceAttributes>(newSource: T, requestCreator: SourceRequestCreator<T>,marshaller: Marshaller<any, Source<T>>): Promise<Source<T>> {
        return this.sourceClient.createSource(newSource, requestCreator, marshaller);
    }

    public async updateSource<T extends SourceAttributes>(source: Source<T>, requestCreator: SourceRequestCreator<T>,marshaller: Marshaller<any, Source<T>>): Promise<Source<T>> {
        return this.sourceClient.updateSource(source, requestCreator, marshaller);
    }

    public async deleteSource(id: string): Promise<boolean> {
        return this.sourceClient.deleteSource(id);
    }

    public async getSubscribers(): Promise<Subscriber[]> {
        return this.subscriberClient.getSubscribers();
    }

    public async createSubscriber(newSubscriber: NewSubscriber): Promise<Subscriber> {
        return this.subscriberClient.createSubscriber(newSubscriber);
    }

    public async updateSubscriber(subscriber: Subscriber): Promise<Subscriber> {
        return this.subscriberClient.updateSubscriber(subscriber);
    }

    public async deleteSubscriber(id: string): Promise<boolean> {
        return this.subscriberClient.deleteSubscriber(id);
    }

    public async getSubscriptions(): Promise<Subscription[]> {
        return this.subscriptionClient.getSubscriptions();
    }

    public async createSubscription(newSubscription: NewSubscription): Promise<Subscription> {
        return this.subscriptionClient.createSubscription(newSubscription);
    }

    public async updateSubscription(subscription: Subscription): Promise<Subscription> {
        return this.subscriptionClient.updateSubscription(subscription);
    }

    public async updateSubscriptions(subscriptions: Subscription[]): Promise<Subscription[]> {
        return Promise.all(subscriptions.map(s => this.updateSubscription(s)));
    }

    public async deleteSubscription(id: string): Promise<boolean> {
        return this.subscriptionClient.deleteSubscription(id);
    }
}
