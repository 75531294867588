

export interface Config {
    apiGatewayUrl: string;
    nextgenNamePrefix: string;
    iamRolePrefix: string;
    s3BucketPrefix: string;
}

export const validateConfig = (config: Config): boolean => {
    if (!config.apiGatewayUrl) {
        return false;
    }
    return true;
}

const bootstrappedConfig: Config = {
    apiGatewayUrl: process.env.REACT_APP_API_GATEWAY_URL || '',
    nextgenNamePrefix: process.env.REACT_APP_NEXTGEN_NAME_PREFIX || '',
    iamRolePrefix: process.env.REACT_APP_IAM_ROLE_PREFIX || '',
    s3BucketPrefix: process.env.REACT_APP_S3_BUCKET_PREFIX || '',
};

if (!validateConfig(bootstrappedConfig)) {
    throw new Error(`Bootstrapped config did not pass validation; config: {${bootstrappedConfig}}`);
}

export default bootstrappedConfig;