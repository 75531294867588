import { Datapoint, EcuDatapointAttributes } from "../model/Datapoint";
import { EcuSourceAttributes, Source } from "../model/Source";


export interface Marshaller<I, O> {
    marshal: (i: I) => O;
};

export const axiosResponseEcuDatapointMarshaller: Marshaller<any, Datapoint<EcuDatapointAttributes>> = {
    marshal: function (i: any): Datapoint<EcuDatapointAttributes> {
        return {
            id: i.id,
            attributes: {
                version: i.version,
                name: i.name,
                description: i.description,
                rate: i.rate,
                transmissionMethod: i.transmission_method,
                type: 'ECU',
                rangeMin: i.range_min,
                rangeMax: i.range_max,
                units: i.units,
                canId: i.can_id,
                ecu: i.ecu,
                msb: i.msb,
                length: i.length,
                gain: i.gain,
            }
        } as Datapoint<EcuDatapointAttributes>
    }
}

export const axiosResponseEcuSourceMarshaller: Marshaller<any, Source<EcuSourceAttributes>> = {
    marshal: function (i: any): Source<EcuSourceAttributes> {
        const datapointTags = new Map<string, any>();
        for (let member in i.datapoint_tags) {
            datapointTags.set(member, i.datapoint_tags[member]);
        }
        return {
            id: i.id,
            attributes: {
                version: i.version,
                name: i.name,
                description: i.description,
                vehicleId: i.vehicle_id,
                datapointTags: datapointTags
            }
        };
    }
}