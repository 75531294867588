import React from 'react';

import './HealthCard.css'

export enum HealthStatus {
    GOOD = 'good',
    OKAY = 'okay',
    BAD = 'bad',
    NEUTRAL = 'neutral'
}

interface HealthCardProps {
    healthStatus: HealthStatus;
    circleContent?: React.ReactNode;
    label: React.ReactNode;
}

export const HealthCard = (props: HealthCardProps) => {
    return (
        <div className='health-card'>
            <div className='health-status-light-container'><div className={`circle health-status-light-${props.healthStatus}`}>{props.circleContent}</div></div>
            <div className="label">{props.label}</div>
        </div>
    );
};
