import React from 'react';
import { extractInputValue } from './common/InputUtils';
import { ConfigurationClient } from './common/ConfigurationClient';
import Button from './components/Button';
import ClearFloat from './components/ClearFloat';
import TabbedView from './components/TabbedView';
import Table from './components/Table';
import TitledView from './components/TitledView';
import { NewSubscriber, Subscriber } from './model/Subscriber';

import './SubscribersView.css';
import bootstrappedConfig from './common/Config';
import { FailureFlashMessage, SuccessFlashMessage } from './components/FlashMessage';
import { FlashMessagesProducer, withFlashMessages } from './common/FlashMessagesContext';
import { styleGenerator } from './common/TableStyles';
import { setForFadeDuration } from "./common/ReactUtils";
import { COLOR_FADE_DELAY, COLOR_FADE_DURATION } from './common/Constants';

const Users = () => {
    return (<></>);
};

const getEmptyNewSubscriber = () => {
    return {
        name: '',
        description: '',
        access: {
            iamRoleArn: '',
            s3Bucket: '',
            replayEnabled: true,
            replayLimits: '',
            downloadEnabled: true,
            downloadLimits: '',
            liveStreamEnabled: true
        },
        nats: {
            primaryAccount: false,
            accountName: '',
            userName: ''
        }
    };
};

const validateSubscriber = (subscriber: NewSubscriber | Subscriber): { valid: boolean, errors: string[] } => {
    let valid = true;
    const errors: string[] = [];
    const setInvalid = (error: string) => {
        valid = false;
        errors.push(error);
    }

    if (!subscriber.name) {
        setInvalid('Subscriber must have a name');
    }
    if (!subscriber.description) {
        setInvalid('Subscriber must have a description');
    }
    if (!subscriber.access.s3Bucket) {
        setInvalid('Subscriber must have an S3 bucket name');
    }
    if (!subscriber.access.iamRoleArn) {
        setInvalid('Subscriber must have an IAM Role ARN');
    }
    if (!subscriber.nats.accountName) {
        setInvalid('Subscriber must have a NATS account name');
    }
    if (!subscriber.nats.userName) {
        setInvalid('Subscriber must have a NATS username');
    }
    return { valid, errors };
};

type SubscriberProps = FlashMessagesProducer & {
    configurationClient: ConfigurationClient;
}

const Subscribers: React.FC<SubscriberProps> = ({ configurationClient, addFlashMessage }) => {

    const [loading, setLoading] = React.useState<boolean>(true);
    const [subscribers, setSubscribers] = React.useState<Subscriber[]>([]);
    const [newSubscriber, setNewSubscriber] = React.useState<NewSubscriber>(getEmptyNewSubscriber());
    const [createRequested, setCreateRequested] = React.useState<boolean>(false);
    const [deleteId, setDeleteId] = React.useState<string>();
    const [updateId, setUpdateId] = React.useState<string>();
    const [successfulId, setSuccessfulId] = React.useState<string>();
    const [failureId, setFailureId] = React.useState<string>();

    const pageSize = 25;
    React.useEffect(() => {
        if (loading) {
            const getSubscribers = async () => {
                const subs = await configurationClient.getSubscribers();
                setSubscribers(subs);
                setLoading(false);
            };
            getSubscribers();
        }

        if (deleteId) {
            const deleteSub = async () => {
                setDeleteId(undefined);
                try {
                    await configurationClient.deleteSubscriber(deleteId);
                    addFlashMessage((<SuccessFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Subscriber deleted</SuccessFlashMessage>));
                    setForFadeDuration(setSuccessfulId, deleteId);
                    setLoading(true);
                } catch (e: any) {
                    addFlashMessage((<FailureFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Delete did not succeed. Try refreshing the page and retrying</FailureFlashMessage>));
                    setForFadeDuration(setFailureId, deleteId);
                }
            }
            deleteSub();
        }
        if (updateId) {
            const updateSub = async () => {
                setUpdateId(undefined);
                const sub = subscribers.find(s => s.id === updateId);
                if (sub) {
                    const validation = validateSubscriber(sub);
                    if (validation.valid) {
                        try {
                            await configurationClient.updateSubscriber(sub);
                            addFlashMessage((<SuccessFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Subscriber updated</SuccessFlashMessage>));
                            setForFadeDuration(setSuccessfulId, updateId);
                            setLoading(true);
                        } catch (e: any) {
                            addFlashMessage((<FailureFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Update did not succeed. Try refreshing the page and retrying</FailureFlashMessage>));
                            setForFadeDuration(setFailureId, updateId);
                        }
                    } else {
                        addFlashMessage((<FailureFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >{validation.errors.map(e => <div>{e}</div>)}</FailureFlashMessage>));
                        setForFadeDuration(setFailureId, updateId);
                    }
                } else {
                    throw new Error(`Subscriber with id: ${updateId} not found`);
                }
            };
            updateSub();
        }
        if (createRequested) {
            const createSub = async () => {
                setCreateRequested(false)
                if (newSubscriber) {
                    const validation = validateSubscriber(newSubscriber);
                    if (validation.valid) {
                        try {
                            const newSub = await configurationClient.createSubscriber(newSubscriber);
                            addFlashMessage((<SuccessFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Subscriber created</SuccessFlashMessage>));
                            setForFadeDuration(setSuccessfulId, newSub.id);
                            setLoading(true);
                            setNewSubscriber(getEmptyNewSubscriber());
                        } catch (e: any) {
                            addFlashMessage((<FailureFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Create did not succeed. Try again</FailureFlashMessage>));
                        }
                    } else {
                        addFlashMessage((<FailureFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >{validation.errors.map(e => <div>{e}</div>)}</FailureFlashMessage>));
                    }
                } else {
                    throw new Error('Something went wrong');
                }
            };
            createSub();
        }
    }, [loading, updateId, deleteId, createRequested]);


    const onCreate = async () => {
        setCreateRequested(true);
    };

    const onUpdateFactory = (id: string) => {
        return async () => {
            setUpdateId(id);
        }
    };

    const onDeleteFactory = (id: string) => {
        return async () => {
            setDeleteId(id);
        }
    };

    const onCancel = () => {
        setNewSubscriber(getEmptyNewSubscriber());
        setLoading(true);
    };

    const onChangeUpdateFactory = <T,>(id: string, valueExtractor: (val: any) => T, callback: (s: Subscriber, input: T) => void) => {
        return (value: T) => {
            const subscriber = subscribers.find(s => s.id === id);
            if (subscriber) {
                const val = valueExtractor(value);
                callback(subscriber, val);
                setSubscribers([...subscribers]);
            } else {
                throw new Error(`Subscriber with ${id} was not found`);
            }
        };
    };

    const nascarNextGenNamePrefix = bootstrappedConfig.nextgenNamePrefix;

    const onChangeUpdateName = (id: string) => onChangeUpdateFactory(id, extractInputValue, (sub, v) => sub.name = v);
    const onChangeUpdateDescription = (id: string) => onChangeUpdateFactory(id, extractInputValue, (sub, v) => sub.description = v);
    /*const onChangeUpdateDownloadLimits = (id: string) => onChangeUpdateFactory(id, extractSelectValue, (sub, v) => sub.access.downloadLimits = v);
    const onChangeUpdateReplayLimits = (id: string) => onChangeUpdateFactory(id, extractSelectValue, (sub, v) => sub.access.replayLimits = v);
    const onChangeUpdateDownloadEnabled = (id: string) => onChangeUpdateFactory(id, extractCheckboxInput, (sub, v) => sub.access.downloadEnabled = v);
    const onChangeUpdateReplayEnabled = (id: string) => onChangeUpdateFactory(id, extractCheckboxInput, (sub, v) => sub.access.replayEnabled = v);
    const onChangeUpdateLivestreamEnabled = (id: string) => onChangeUpdateFactory(id, extractCheckboxInput, (sub, v) => sub.access.liveStreamEnabled = v); */

    const onChangeCreateFactory = <T,>(valueExtractor: (val: any) => T, callback: (input: T) => void) => {
        return (value: T) => {
            const val = valueExtractor(value);
            callback(val);
            setNewSubscriber({...newSubscriber});
        }
    };

    const onChangeCreateName = onChangeCreateFactory(extractInputValue, (v) => newSubscriber.name = v);
    const onChangeCreateDescription = onChangeCreateFactory(extractInputValue, (v) => newSubscriber.description = v);
    /*const onChangeCreateDownloadLimits = onChangeCreateFactory(extractSelectValue, (v) => newSubscriber.access.downloadLimits = v);
    const onChangeCreateReplayLimits = onChangeCreateFactory(extractSelectValue, (v) => newSubscriber.access.replayLimits = v);
    const onChangeCreateDownloadEnabled = onChangeCreateFactory(extractCheckboxInput, (v) => newSubscriber.access.downloadEnabled = v);
    const onChangeCreateReplayEnabled = onChangeCreateFactory(extractCheckboxInput, (v) => newSubscriber.access.replayEnabled = v);
    const onChangeCreateLivestreamEnabled = onChangeCreateFactory(extractCheckboxInput, (v) => newSubscriber.access.liveStreamEnabled = v); */
    const onChangeCreateConfigInfo = (iamRolePrefix: string) => onChangeCreateFactory(extractInputValue, (v) => {
        newSubscriber.nats.accountName = v.toUpperCase();
        newSubscriber.nats.userName = v.toLowerCase();
        if (v) {
            newSubscriber.access.iamRoleArn = iamRolePrefix + nascarNextGenNamePrefix + v.toLowerCase();
            newSubscriber.access.s3Bucket = nascarNextGenNamePrefix + v.toLowerCase();
        } else {
            newSubscriber.access.iamRoleArn = '';
            newSubscriber.access.s3Bucket = '';
        }
    });

    const roleArnPrefixRegex = /^arn:aws:iam::\d{12}:role\//g;
    //const s3PrefixRegex = /^arn:aws:s3:::/g;
    //const downloadLimits = ['60', '120'];
    //const replayLimits = ['60', '120'];
    const columnDefs = [
        {
            id: 'subscriber-table-name',
            classNamePrefix: 'subscriber-table-name',
            keyGen: (s: Subscriber) => 'name',
            header: 'Name',
            cell: (s: Subscriber) => <input disabled={true} value={s.name} className='subscriber-table-name-input' placeholder='Name' onChange={onChangeUpdateName(s.id)} />,
            createRowCell: () => <input value={newSubscriber.name} className='subscriber-table-name-input' placeholder='Name' onChange={onChangeCreateName} />,
            filterPredicate: (s: Subscriber, filterText: string) => s.name.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
        },
        {
            id: 'subscriber-table-description',
            classNamePrefix: 'subscriber-table-description',
            keyGen: (s: Subscriber) => 'description',
            header: 'Description',
            cell: (s: Subscriber) => <input value={s.description} className='subscriber-table-description-input' placeholder='Description' onChange={onChangeUpdateDescription(s.id)} />,
            createRowCell: () => <input value={newSubscriber.description} className='subscriber-table-description-input' placeholder='Description' onChange={onChangeCreateDescription} />,
            filterPredicate: (s: Subscriber, filterText: string) => s.description.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
        },
        {
            id: 'subscriber-table-nats-account-name',
            classNamePrefix: 'subscriber-table-nats-account-name',
            keyGen: (s: Subscriber) => 'nats-account-name',
            header: 'NATS Account Name',
            cell: (s: Subscriber) => <input value={s.nats.accountName} disabled={true} className='subscriber-table-nats-account-name-input' placeholder='NATS Account Name' />,
            createRowCell: () => <input value={newSubscriber.nats.accountName} className='subscriber-table-nats-account-name-input' placeholder='NATS Account Name' onChange={onChangeCreateConfigInfo(bootstrappedConfig.iamRolePrefix)} />,
            filterPredicate: (s: Subscriber, filterText: string) => s.nats.accountName.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
        },
        {
            id: 'subscriber-table-user-name',
            classNamePrefix: 'subscriber-table-nats-username',
            keyGen: (s: Subscriber) => 'user-name',
            header: 'NATS Username',
            cell: (s: Subscriber) => <input value={s.nats.userName} disabled={true} className='subscriber-table-nats-username-input' placeholder='NATS Username' />,
            createRowCell: () => <input disabled={true} value={newSubscriber.nats.userName} className='subscriber-table-nats-username-input' placeholder='' />,
            filterPredicate: (s: Subscriber, filterText: string) => s.nats.userName.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
        },
        {
            id: 'subscriber-table-resource-name',
            classNamePrefix: 'subscriber-table-resource-name',
            keyGen: (s: Subscriber) => 'resource-name',
            header: 'Resource Name',
            cell: (s: Subscriber) => <input disabled={true} value={s.access.iamRoleArn.replace(roleArnPrefixRegex, '')} className='subscriber-table-resource-name-input' placeholder='' />,
            createRowCell: () => <input disabled={true} value={newSubscriber.access.iamRoleArn.replace(roleArnPrefixRegex, '')} className='subscriber-table-resource-name-input' placeholder='' />,
            filterPredicate: (s: Subscriber, filterText: string) => s.access.s3Bucket.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
        },
        /*{
            id: 'subscriber-table-iam-role-name',
            classNamePrefix: 'subscriber-table-iam-role-name',
            keyGen: (s: Subscriber) => 'iam-role-name',
            header: 'IAM Role Name',
            cell: (s: Subscriber) => <input disabled={true} value={s.access.iamRoleArn.replace(roleArnPrefixRegex, '')} className='subscriber-table-iam-role-name-input' placeholder='IAM Role Name' />,
            createRowCell: () => <input disabled={true} value={newSubscriber.access.iamRoleArn.replace(roleArnPrefixRegex, '')} className='subscriber-table-iam-role-name-input' placeholder='IAM Role Name' />
        },
        {
            id: 'subscriber-table-s3-bucket',
            classNamePrefix: 'subscriber-table-s3-bucket',
            keyGen: (s: Subscriber) => 's3-bucket',
            header: 'S3 Bucket Name',
            cell: (s: Subscriber) => <input disabled={true} value={s.access.s3Bucket.replace(s3PrefixRegex, '')} className='subscriber-table-s3-bucket-input' placeholder='S3 Bucket' />,
            createRowCell: () => <input disabled={true} value={newSubscriber.access.s3Bucket.replace(s3PrefixRegex, '')} className='subscriber-table-s3-bucket-input' placeholder='S3 Bucket Name' />
        },*/
        /*{
            id: 'subscriber-table-download-limits',
            classNamePrefix: 'subscriber-table-download-limits',
            keyGen: (s: Subscriber) => 'download-limits',
            header: 'Download Limits',
            cell: (s: Subscriber) => <Select items={downloadLimits} selectedValue={s.access.downloadLimits} option={l => { return { value: l, label: l }; }} onChange={onChangeUpdateDownloadLimits(s.id)} />,
            createRowCell: () => <Select selectedValue={newSubscriber.access.downloadLimits} items={downloadLimits} placeholder='Select' option={l => { return { value: l, label: l }; }} onChange={onChangeCreateDownloadLimits} />
        },
        {
            id: 'subscriber-table-replay-limits',
            classNamePrefix: 'subscriber-table-replay-limits',
            keyGen: (s: Subscriber) => 'replay-limits',
            header: 'Replay Limits',
            cell: (s: Subscriber) => <Select items={replayLimits} selectedValue={s.access.replayLimits} option={l => { return { value: l, label: l }; }} onChange={onChangeUpdateReplayLimits(s.id)} />,
            createRowCell: () => <Select selectedValue={newSubscriber.access.replayLimits} items={replayLimits} placeholder='Select' option={l => { return { value: l, label: l }; }} onChange={onChangeCreateReplayLimits} />
        },
        {
            id: 'subscriber-table-replay-enabled',
            classNamePrefix: 'subscriber-table-replay-enabled',
            keyGen: (s: Subscriber) => 'replay-enabled',
            header: 'Replay Enabled',
            cell: (s: Subscriber) => <ToggleSwitch on={s.access.replayEnabled} onChange={onChangeUpdateReplayEnabled(s.id)} />,
            createRowCell: () => <ToggleSwitch on={newSubscriber.access.replayEnabled} onChange={onChangeCreateReplayEnabled} />
        },
        {
            id: 'subscriber-table-download-enabled',
            classNamePrefix: 'subscriber-table-download-enabled',
            keyGen: (s: Subscriber) => 'download-enabled',
            header: 'Download Enabled',
            cell: (s: Subscriber) => <ToggleSwitch on={s.access.downloadEnabled} onChange={onChangeUpdateDownloadEnabled(s.id)} />,
            createRowCell: () => <ToggleSwitch on={newSubscriber.access.downloadEnabled} onChange={onChangeCreateDownloadEnabled} />
        },
        {
            id: 'subscriber-table-livestream-enabled',
            classNamePrefix: 'subscriber-table-livestream-enabled',
            keyGen: (s: Subscriber) => 'livestream-enabled',
            header: 'Livestream Enabled',
            cell: (s: Subscriber) => <ToggleSwitch on={s.access.liveStreamEnabled} onChange={onChangeUpdateLivestreamEnabled(s.id)} />,
            createRowCell: () => <ToggleSwitch on={newSubscriber.access.liveStreamEnabled} onChange={onChangeCreateLivestreamEnabled} />
        },*/
        {
            id: 'subscriber-table-action',
            classNamePrefix: 'subscriber-table-action',
            keyGen: (s: Subscriber) => 'action',
            header: '',
            cell: (s: Subscriber) => <div className='buttons-container'><div className='delete-button-container'><Button className='delete-button' onClick={onDeleteFactory(s.id)}>Delete</Button></div><div className='update-button-container'><Button className='update-button' onClick={onUpdateFactory(s.id)}>Update</Button></div></div>,
            createRowCell: () => <div><div className='create-button-container'><Button className="create-button" onClick={onCreate} >Create</Button></div></div>
        },
    ];

    const style = styleGenerator(successfulId, failureId);

    return (
        <div>
            <Table loading={loading} includeCreateRow={true} header={null} keyGen={s => s.id} columnDefs={columnDefs} items={subscribers} pageSize={25} style={style} />
            <div className='control-box'>
                <Button className='cancel-button' onClick={onCancel}>Cancel</Button>
            </div>
            <ClearFloat />
        </div>
    );
};

const Groups = () => {
    return (<></>);
};

type SubscribersViewProps = FlashMessagesProducer & {
    configurationClient: ConfigurationClient;
}
const SubscribersView: React.FC<SubscribersViewProps> = ({ configurationClient, addFlashMessage }) => {
    return (
        <TitledView title="Subscribers">
            <div className="subscribers-page">
                <TabbedView toggleButtonClassName='subscribers-toggle-button' views={[
                    //{title: 'users', label: 'Users', view: <Users />},
                    {title: 'subscribers', label: 'Subscribers', view: <Subscribers configurationClient={configurationClient} addFlashMessage={addFlashMessage} />},
                    //{title: 'groups', label: 'Groups', view: <Groups />}
                    ]} />
            </div>
        </TitledView>
    );
};

export default withFlashMessages(SubscribersView);
