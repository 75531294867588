import React from "react";

import { ConfigurationClient } from "./common/ConfigurationClient";
import Button from './components/Button';
import ClearFloat from './components/ClearFloat';
import Select from "./components/Select";
import { SuccessFlashMessage, FailureFlashMessage } from "./components/FlashMessage";
import Table from './components/Table';
import TitledView from './components/TitledView';
import { Datapoint, DatapointAttributes } from "./model/Datapoint";
import { Subscription, NewSubscription } from "./model/Subscription";
import { Source, SourceAttributes } from "./model/Source";
import Subscriber from "./model/Subscriber";
import { axiosResponseEcuDatapointMarshaller, axiosResponseEcuSourceMarshaller } from "./common/Marshaller";
import { extractSelectValue } from "./common/InputUtils";

import './SubscriptionView.css';
import { FlashMessagesProducer, withFlashMessages } from "./common/FlashMessagesContext";
import { setForFadeDuration } from "./common/ReactUtils";
import { styleGenerator } from "./common/TableStyles";
import { COLOR_FADE_DELAY, COLOR_FADE_DURATION } from './common/Constants';

const datapointOption = (d: Datapoint<DatapointAttributes>) => {
    return {
            value: d.id,
            label: d.attributes.name
    };
};

const sourceOption = (s: Source<SourceAttributes>) => {
    return {
            value: s.id,
            label: s.attributes.name
    };
};

const subscriberOption = (s: Subscriber) => {
    return {
        value: s.id,
        label: s.name
    };
};

interface CancelButtonProps {
    cancel: () => void;
}

const CancelButton: React.FunctionComponent<CancelButtonProps> = ({ cancel, children }) => {
    return (
        <Button className="cancel-button" onClick={cancel} >{children}</Button>
    );
};

const getEmptyNewSubscription = () => {
    return {
        name: '',
        description: '',
        datapointId: '',
        sourceId: '',
        subscriberId: '',
        enabled: false
    };
};

const validateSubscription = (subscription: { datapointId: string, sourceId: string, subscriberId: string}) => {
    let valid = true;
    const errors: string[] = [];
    const setInvalid = (error: string) => {
        valid = false;
        errors.push(error);
    }

    if (!subscription.datapointId) {
        setInvalid('Subscription must have a datapoint');
    }
    if (!subscription.sourceId) {
        setInvalid('Subscription must have a source');
    }
    if (!subscription.subscriberId) {
        setInvalid('Subscription must have a subscriber');
    }
    return { valid, errors };
};

type SubscriptionProps = FlashMessagesProducer & {
    datapoints: Datapoint<DatapointAttributes>[];
    sources: Source<SourceAttributes>[];
    subscribers: Subscriber[];
    configurationClient: ConfigurationClient;
}

const Subscriptions: React.FunctionComponent<SubscriptionProps> = ({ datapoints, sources, subscribers, configurationClient, addFlashMessage }) => {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [createRequested, setCreateRequested] = React.useState<boolean>(false);
    const [deleteId, setDeleteId] = React.useState<string>();
    const [updateId, setUpdateId] = React.useState<string>();
    const [newSubscription, setNewSubscription] = React.useState<NewSubscription>(getEmptyNewSubscription());
    const [subscriptions, setSubscriptions] = React.useState<Subscription[]>([]);
    const [successfulId, setSuccessfulId] = React.useState<string>();
    const [failureId, setFailureId] = React.useState<string>();

    React.useEffect(() => {
        if (loading) {
            const getSubscriptions = async () => {
                try {
                    const subs = await configurationClient.getSubscriptions();
                    setSubscriptions(subs);
                    setLoading(false);
                } catch (e: any) {
                    addFlashMessage((<FailureFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Fetch did not succeed. Try refreshing the page</FailureFlashMessage>));
                }
            }
            getSubscriptions();
        }
        if (deleteId) {
            const deleteSubscription = async () => {
                setDeleteId(undefined);
                try {
                    await configurationClient.deleteSubscription(deleteId);
                    addFlashMessage((<SuccessFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Subscription deleted</SuccessFlashMessage>));
                    setForFadeDuration(setSuccessfulId, deleteId);
                    setLoading(true);
                } catch (e: any) {
                    addFlashMessage((<FailureFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Delete did not succeed. Try refreshing the page and retrying</FailureFlashMessage>));
                    setForFadeDuration(setFailureId, deleteId);
                }
            }
            deleteSubscription();
        }
        if (updateId) {
            const updateSubscription = async () => {
                setUpdateId(undefined);
                const subscription = subscriptions.find(s => s.id === updateId);
                if (subscription) {
                    const validation = validateSubscription(subscription);
                    if (validation.valid) {
                        try {
                            await configurationClient.updateSubscription(subscription);
                            addFlashMessage((<SuccessFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Subscription updated</SuccessFlashMessage>));
                            setForFadeDuration(setSuccessfulId, updateId);
                            setLoading(true);
                        } catch (e: any) {
                            addFlashMessage((<FailureFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Update did not succeed. Try refreshing the page and retrying</FailureFlashMessage>));
                            setForFadeDuration(setFailureId, updateId);
                        }
                    } else {
                        addFlashMessage((<FailureFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >{validation.errors.map(e => <div>{e}</div>)}</FailureFlashMessage>));
                        setForFadeDuration(setFailureId, updateId);
                    }
                } else {
                    throw new Error(`Subscription with id: ${updateId} not found`);
                }
            }
            updateSubscription();
        }
        if (createRequested) {
            const createSubscription = async () => {
                setCreateRequested(false)
                if (newSubscription) {
                    const validation = validateSubscription(newSubscription);
                    if (validation.valid) {
                        try {
                            const justCreatedSubscription = await configurationClient.createSubscription(newSubscription);
                            addFlashMessage((<SuccessFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Subscription created</SuccessFlashMessage>));
                            setForFadeDuration(setSuccessfulId, justCreatedSubscription.id);
                            setLoading(true);
                            setNewSubscription(getEmptyNewSubscription());
                        } catch (e: any) {
                            addFlashMessage((<FailureFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >Create did not succeed. Try again</FailureFlashMessage>));
                        }
                    } else {
                        addFlashMessage((<FailureFlashMessage delay={COLOR_FADE_DELAY} duration={COLOR_FADE_DURATION} >{validation.errors.map(e => <div>{e}</div>)}</FailureFlashMessage>));
                    }
                } else {
                    throw new Error('Something went wrong');
                }
            }
            createSubscription();
        }
    }, [loading, createRequested, updateId, deleteId]);


    const onChangeCreateFactory = <T,>(valueExtractor: (val: any) => T, callback: (input: T) => void) => {
        return (value: T) => {
            const val = valueExtractor(value);
            callback(val);
            setNewSubscription({...newSubscription});
        }
    };
    const localNewSubscriptionDatapointUpdate = onChangeCreateFactory(extractSelectValue, (v) => newSubscription.datapointId = v);
    const localNewSubscriptionSubscriberUpdate = onChangeCreateFactory(extractSelectValue, (v) => newSubscription.subscriberId = v);
    const localNewSubscriptionSourceUpdate  = onChangeCreateFactory(extractSelectValue, (v) => newSubscription.sourceId = v);

    const onCancel = async () => {
        setNewSubscription(getEmptyNewSubscription());
        setLoading(true);
    };

    const onDeleteFactory = (id: string) => {
        return async () => {
            setDeleteId(id)
        };
    };

    const onCreate = async () => {
        setCreateRequested(true);
    }

    const columnDefs = [ 
        /*{
            id: 'subscription-table-name',
            classNamePrefix: 'subscription-table-name',
            header: 'Name',
            cell: (s: Subscription) => s.name,
        },*/
        {
            id: 'subscription-create-table-datapoint',
            classNamePrefix: 'subscription-table-datapoint',
            keyGen: (s: Subscription) => 'datapoint',
            header: 'Datapoint',
            cell: (s: Subscription) => <Select className={'datapoint-select'} placeholder='Select' isDisabled={true} selectedValue={s.datapointId} items={datapoints} option={datapointOption} />,
            createRowCell: () => <Select className={'datapoint-select'} placeholder='Select' selectedValue={newSubscription.datapointId} items={datapoints} option={datapointOption} onChange={localNewSubscriptionDatapointUpdate} />,
            filterPredicate: (s: Subscription, filterText: string) => datapoints.find(d => d.id === s.datapointId)?.attributes.name.toLocaleLowerCase().includes(filterText.toLocaleLowerCase()) || false
        },
        {
            id: 'subscription-table-source',
            classNamePrefix: 'subscription-table-source',
            keyGen: (s: Subscription) => 'source',
            header: 'Source',
            cell: (s: Subscription) => <Select className={'source-select'} isDisabled={true} placeholder='Select' items={sources} selectedValue={s.sourceId} option={sourceOption} />,
            createRowCell: () => <Select className={'source-select'} placeholder='Select' onChange={localNewSubscriptionSourceUpdate} items={sources} selectedValue={newSubscription.sourceId} option={sourceOption} />,
            filterPredicate: (s: Subscription, filterText: string) => sources.find(source => source.id === s.sourceId)?.attributes.name.toLocaleLowerCase().includes(filterText.toLocaleLowerCase()) || false
        },
        {
            id: 'subscription-table-subscriber',
            classNamePrefix: 'subscription-table-subscriber',
            keyGen: (s: Subscription) => 'subscriber',
            header: 'Subscriber',
            cell: (s: Subscription) => <Select className={'subscriber-select'} isClearable={true} isDisabled={true} selectedValue={s.subscriberId} items={subscribers} option={subscriberOption} />,
            createRowCell: () => <Select className={'subscriber-select'} isClearable={true} onChange={localNewSubscriptionSubscriberUpdate} selectedValue={newSubscription.subscriberId} items={subscribers} option={subscriberOption} />,
            filterPredicate: (s: Subscription, filterText: string) => subscribers.find(sub => sub.id === s.subscriberId)?.name.toLocaleLowerCase().includes(filterText.toLocaleLowerCase()) || false
        },
        {
            id: 'subscription-table-action',
            classNamePrefix: 'subscription-table-action',
            keyGen: (s: Subscription) => 'action',
            header: '',
            cell: (s: Subscription) => <div className="buttons-container"><div className="delete-button-container"><Button className="delete-button" onClick={onDeleteFactory(s.id)}>Delete</Button></div></div>,
            createRowCell: () => <div className="create-button-container"><Button className="create-button" onClick={onCreate} >Create</Button></div>
        },
        /*{
            id: 'subscription-table-enabled',
            classNamePrefix: 'subscription-table-enabled',
            header: 'Enabled',
            cell: (s: Subscription) => <ToggleSwitch onChange={localSubscriptionEnabledUpdate(s.id)} on={s.enabled} />,
        }*/
    ];
    const style = styleGenerator(successfulId, failureId);
    return (
        <div className="subscription-view">
            <Table keyGen={(d: Subscription) => d.id} loading={loading} header={undefined} includeCreateRow={true} columnDefs={columnDefs} items={subscriptions} pageSize={10} style={style} />
            <div className="control-box">
                <CancelButton cancel={onCancel}>Cancel</CancelButton>
            </div>
            <ClearFloat />
        </div>
    );
};

interface SubscriptionViewProps extends FlashMessagesProducer {
    configurationClient: ConfigurationClient;
}

const SubscriptionView: React.FunctionComponent<SubscriptionViewProps> = ( { configurationClient, addFlashMessage }) => {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [datapoints, setDatapoints] = React.useState<Datapoint<DatapointAttributes>[]>([]);
    const [sources, setSources] = React.useState<Source<SourceAttributes>[]>([]);
    const [subscribers, setSubscribers] = React.useState<Subscriber[]>([]);
    React.useEffect(() => {
        if (loading) {
            const getAll = async () => {
                setSources(await configurationClient.getSources(axiosResponseEcuSourceMarshaller));
                setSubscribers(await configurationClient.getSubscribers());
                setDatapoints(await configurationClient.getDatapoints(axiosResponseEcuDatapointMarshaller));
                setLoading(false);
            };
            getAll();
        }
    }, [loading]);
    return (
        <TitledView title="NextGen Subscriptions">
            <div className="subscription-page">
                <Subscriptions configurationClient={configurationClient} datapoints={datapoints} sources={sources} subscribers={subscribers} addFlashMessage={addFlashMessage} />
            </div>
        </TitledView>
    );
};

export default withFlashMessages(SubscriptionView);
