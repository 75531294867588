import Versioned from './Versioned';
import Entity from './Entity';

export const units = ['Pounds per square inch', 'Revolutions per minute', 'Percentage', "Degrees (F)"] as const;
export type Unit = typeof units[0];

export const transmissionMethods = ['CANBus'] as const;
export type TransmissionMethod = typeof transmissionMethods[number];

export const canIds = ['x400', 'x401', 'x402', 'x403', 'x600'] as const;
export type CanID = typeof canIds[number];

export const ecus = ['ecu0', 'ecu1', 'ecu2', 'ecu3', 'ecumux'] as const;
export type ECU = typeof ecus[number];

export const broadcastTypes = ['broadcast', 'unicast'] as const;
export type BroadcastType = typeof broadcastTypes[number];

const datapointTypes = ['ECU', 'ECUMUX', 'T&SAPI'] as const;
export type DatapointType = typeof datapointTypes[number];

export type DatapointAttributes = Versioned & {
    type: DatapointType;
    name: string;
    description: string;
    rate: number;
    transmissionMethod?: TransmissionMethod;
}

export interface EcuDatapointAttributes extends DatapointAttributes {
    type: 'ECU',
    rangeMin: number;
    rangeMax: number;
    units?: Unit;
    canId: Number;
    ecu: ECU;
    msb: number;
    length: number;
    gain: number;
}

export interface MuxDatapointAttributes  extends DatapointAttributes  {
    type: 'ECUMUX',
    rangeMin: number;
    rangeMax: number;
    units?: Unit;
    canId: Number;
    ecu: ECU;
    msb: number;
    length: number;
    gain: number;
    muxMsb: number;
    muxLength: number;
    muxId: number;
}

export interface TimingAndScoringDatapointAttributes extends DatapointAttributes  {
    type: 'T&SAPI',
    apiRoute: string;
    bsi: {
        publicationCanId: string;
        broadcastType: BroadcastType;
        message: object;
    }
}

export type NewDatapoint = DatapointAttributes;

export type NewEcuDatapoint = EcuDatapointAttributes;

export type NewMuxDatapoint = MuxDatapointAttributes;

export interface NewTimingAndScoringDatapoint extends NewDatapoint {
    apiRoute: string;
    bsi: {
        publicationCanId: string;
        broadcastType: BroadcastType;
        message: object;
    }
}

export type Datapoint<T extends DatapointAttributes> = Entity & {
    attributes: T;
}
