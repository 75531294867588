import React from "react";

import './TitledView.css';

interface TitledViewProps {
    title: string;
}

const TitledView: React.FunctionComponent<TitledViewProps> = ({ title, children }) => {
    return (
        <div className="titled-view">
            <div className="view-title">{title}</div>
            <hr />
            { children }
        </div>
    );
};

export default TitledView;
