import Button from "./components/Button";
import { DownloadClient } from './common/DownloadClient';
import Table from "./components/Table";
import React from "react";
import Select from "./components/Select";
import { extractSelectValue } from "./common/InputUtils";
import Subscriber from "./model/Subscriber";
import { ConfigurationClient } from "./common/ConfigurationClient";

import './DownloadView.css';

interface DownloadViewProps {
    configurationClient: ConfigurationClient;
    downloadClient: DownloadClient;
}

const Instructions = () => {
    const authCode = `export AWS_ACCESS_KEY_ID="YOUR_ACCESS_KEY_ID";
export AWS_SECRET_ACCESS_KEY="YOUR_SECRET_ACCESS_KEY";
export AWS_SESSION_TOKEN="YOUR_SESSION_TOKEN";`;
    const code = `#!/bin/bash
manifest_file=YOUR_MANIFEST_FILE;
read -r bucket<$manifest_file;
echo "Bucket: $bucket";
for s3_key in $(tail -n +2 $manifest_file); do
    mkdir -p $(dirname $s3_key);
    aws s3 cp s3://$bucket/$s3_key ./$s3_key;
done`;
    return (
        <div className="download-instructions">
            <p>Use the above interface to download a manifest file containing all datapoint
                files for the time frame of the event. Make sure you have AWS CLI installed
                and that your terminal has a method for authentication. The easiest authentication
                method is to go to the console and copy the command-line access credentials.
                They will look like this:
            </p>
            <pre className="download-code" >
                {authCode}
            </pre>
            <p>
                If you do use that auth method, note that your secrets must not expire during
                the download; make sure to set the expiration for your secret to be long enough to facilitate download.

                After setting up terminal authentication, use the manifest file in the following script:
            </p>
            <pre className="download-code">
                {code}
            </pre>
            <p>You will need to substitute <var>YOUR_MANIFEST_FILE</var></p>
        </div>
    );
}

const DownloadView: React.FC<DownloadViewProps> = ({ configurationClient, downloadClient }) => {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [loop, setLoop] = React.useState<boolean>(true);
    const [downloadRequested, setDownloadRequested] = React.useState<boolean>(false);
    const [subscribers, setSubscribers] = React.useState<Subscriber[]>([]);
    const [eventNames, setEventNames] = React.useState<string[]>([]);
    const [selectedEventName, setSelectedEventName] = React.useState<string>('');
    const [selectedSubscriber, setSelectedSubscriber] = React.useState<string>('');
    const [eventToTimestamps, setEventToTimestamps] = React.useState<Map<string, [string, string]>>();

    const dateToStr = (d: Date): string => {
        return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;
    }

    React.useEffect(() => {
        if (loading) {
            setEventNames(['Daytona 500 - 2021', 'Daytona 500 - 2022', 'Texas Grand Prix - 2021', 'Texas Grand Prix - 2022', 'Toyota Owners 400 - 2022']);
            setEventToTimestamps(
                new Map<string, [string, string]>(
                    [
                        ['Daytona 500 - 2021', [ dateToStr(new Date('February 14, 2021 00:00:00')), dateToStr(new Date('February 15, 2021 00:00:00')) ]],
                        ['Daytona 500 - 2022', [ dateToStr(new Date('February 20, 2022 00:00:00')), dateToStr(new Date('February 20, 2022 23:59:59')) ]],
                        ['Texas Grand Prix - 2021', [ dateToStr(new Date('May 23, 2021 00:00:00')), dateToStr(new Date('May 23, 2021 23:59:59')) ]],
                        ['Texas Grand Prix - 2022', [ dateToStr(new Date('March 27, 2022 00:00:00')), dateToStr(new Date('March 27, 2022 23:59:59')) ]],
                        ['Toyota Owners 400 - 2022', [ dateToStr(new Date('April 3, 2022 00:00:00')), dateToStr(new Date('April 3, 2022 23:59:59')) ]]
                    ]
                )
            );
            const getSubscribers = async () => {
                setSubscribers(await configurationClient.getSubscribers());
            }
            getSubscribers();
            setSelectedEventName('Daytona 500 - 2021');
            setLoading(false);
        }
        if (downloadRequested) {
            const doDownload = async () => {
                const eventTimes = eventToTimestamps?.get(selectedEventName);
                if (eventTimes) {
                    const manifestPresignedUrl = await downloadClient.generateManifestFile(selectedSubscriber, eventTimes[0], eventTimes[1]);
                    window.location.href = manifestPresignedUrl;
                    setDownloadRequested(false);
                }
            }
            doDownload();
        }
    }, [loading, downloadRequested]);

    const onClickDownload = async () => {
        setDownloadRequested(true);
    }
    const downloadButton = <Button className="download-button" onClick={onClickDownload}>Download</Button>;
    const eventNameOption = (l: string) => {
        return {
            value: l,
            label: l
        };
    }
    const subscriberOption = (s: Subscriber) => {
        return {
            value: s.id,
            label: s.name
        };
    }
    const updateSelectedEventName = (val: any) => {
        const value = extractSelectValue(val);
        setSelectedEventName(value);
    }
    const updateSelectedSubscriber = (val: any) => {
        const value = extractSelectValue(val);
        setSelectedSubscriber(value);
    }
    const columnDefs = [
        {
            id: 'download-table-subscriber',
            classNamePrefix: 'download-table-subscriber',
            keyGen: (s: any) => 'subscriber',
            header: 'Subscriber',
            cell: (s: any) => <Select className='subscriber-select' placeholder="Select" selectedValue={selectedSubscriber} onChange={updateSelectedSubscriber} items={subscribers} option={subscriberOption} />,
        },
        {
            id: 'download-table-event-name',
            classNamePrefix: 'download-table-event-name',
            keyGen: (s: any) => 'event name',
            header: 'Event Name',
            cell: (s: any) => <Select className='event-name-select' placeholder="Select" selectedValue={selectedEventName} onChange={updateSelectedEventName} items={eventNames} option={eventNameOption} />,
        },
        {
            id: 'download-table-download',
            classNamePrefix: 'download-table-download',
            keyGen: (s: any) => 'download',
            header: '',
            cell: (s: any) => <div className="buttons-container">{downloadButton}</div>
        },
    ];
    const items = [
        {

        }
    ];
    return (
        <div>
            <Table header={undefined} keyGen={a => 'action'} hidePaginationInfo={true} columnDefs={columnDefs} items={items} />
            <Instructions />
        </div>
    );
}

export default DownloadView;