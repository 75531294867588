import { AxiosInstance } from 'axios';
import { Source, SourceAttributes } from '../model/Source';
import { Marshaller } from './Marshaller';
import { SourceRequestCreator } from './SourceRequestCreator';

export class SourceClient {

    private service: AxiosInstance;
    constructor(service: AxiosInstance) {
        this.service = service;
    }

    public async getSources<T extends SourceAttributes>(marshaller: Marshaller<any, Source<T>>, filter?: (i: any) => boolean): Promise<Source<T>[]> {
        const result = await this.service.get('/configuration/sources');
        const sources = result.data.sources;
        const filteredKeys = (Object.keys(sources) as Array<string>).filter(k => {
            if (!filter) return true;
            return filter(sources[k]);
        });
        return filteredKeys.map(k => {
            const source = sources[k];
            return marshaller.marshal(source);
        }) as Source<any>[];
    }

    public async updateSource<T extends SourceAttributes>(source: Source<T>, requestCreator: SourceRequestCreator<T>, sourceMarshaller: Marshaller<any, Source<T>>): Promise<Source<T>> {
        const body = {
            ...requestCreator.createUpdateRequest(source),
        };
        const result = await this.service.put(`/configuration/sources/${source.id}`, body);
        if (result.data.statusCode != 200) {
            throw new Error(`Error updating source with id: ${source.id}; error message: ${result.data.message}`);
        } else {
            return sourceMarshaller.marshal(result.data.updated_source);
        }
    }

    public async  createSource<T extends SourceAttributes>(source: T, requestCreator: SourceRequestCreator<T>, sourceMarshaller: Marshaller<any, Source<T>>): Promise<Source<T>> {
        const body = {
            ...requestCreator.createCreateRequest(source),
        };
        const result = await this.service.post('/configuration/sources', body);
        if (result.data.statusCode != 200) {
            throw new Error(`Error creating source; error message: ${result.data.message}`);
        }
        const s = sourceMarshaller.marshal(result.data.created_source);
        return s;
    }

    public async deleteSource(sourceId: string): Promise<boolean> {
        const result = await this.service.delete(`/configuration/sources/${sourceId}`);
        console.log(result.data.statusCode);
        if (result.data.statusCode != 200) {
            throw new Error(`Error deleting source with id: ${sourceId}; error message: ${result.data.message}`);
        } else {
            return new Promise<boolean>((resolve, reject) => resolve(true));
        }
    }

}