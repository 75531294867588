import React from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { AmplifyAuthContainer, AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { BrowserRouter } from 'react-router-dom';

import './App.css';
import awsconfig from './aws-exports';
import AppLayout from './AppLayout';
import { User } from './auth/User';
import AppRoutes from './AppRoutes';
import { AuthContext } from './auth/AuthContext';
import bootstrappedConfig from './common/Config';
import { AxiosInstanceContext } from './common/AxiosInstanceContext';
import { AxiosInstanceProvider } from './common/AxiosInstanceProvider';

const config = awsconfig;
Auth.configure(config);
Amplify.register(Auth);

const App: React.FunctionComponent = () => {

    const [authState, setAuthState] = React.useState<AuthState>();
    const [user, setUser] = React.useState<User | undefined>();

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState: AuthState, authData: object | undefined) => {
            setAuthState(nextAuthState);
            const user = authData as User;
            setUser(user);
       })
    }, []);

    const content = authState === AuthState.SignedIn && user ?
    (
        <BrowserRouter>
            <AppLayout content={<AppRoutes />} />
        </BrowserRouter>
    ) : (
        <AmplifyAuthContainer><AmplifyAuthenticator /> </AmplifyAuthContainer>
    );

    const getToken = async (): Promise<string> => {
        const token = Auth.currentSession().then(
            s => {
                return s.getIdToken();
            }
        )
        return token.then(t => t.getJwtToken())
    };

    const url = bootstrappedConfig.apiGatewayUrl;
    const axiosInstance = new AxiosInstanceProvider(url, getToken).getInstance();
    return (
        <div className="App">
            <AuthContext.Provider value={{ user: user }}>
                <AxiosInstanceContext.Provider value={{ axiosInstance: axiosInstance }} >
                    {content}
                </AxiosInstanceContext.Provider>
            </AuthContext.Provider>
        </div>
    );

};

export default App;
