import React from 'react';
import { COLOR_FADE_DELAY, COLOR_FADE_DURATION } from '../common/Constants';
import { extractInputValue } from '../common/InputUtils';
import Button from './Button';
import './Table.css';

interface ColumnDef<T> {
    id: string;
    classNamePrefix?: string;
    header: string;
    cell: (t: T) => React.ReactNode;
    createRowCell?: () => React.ReactNode;
    filterPredicate?: (t: T, filterText: string) => boolean;
}

interface TableProps<T> {
    header: React.ReactNode;
    keyGen: (t: T) => string;
    style?: (i: T) => string;
    rowColorFadeDelay?: number;
    rowColorFadeDuration?: number;
    columnDefs: ColumnDef<T>[];
    items: T[];
    includeCreateRow?: boolean;
    loading?: boolean
    pageSize?: number;
    hidePaginationInfo?: boolean;
}

const Table = <T extends any> (props: TableProps<T>) => {
    const [filterText, setFilterText] = React.useState<string>('');
    const [selectedPage, setSelectedPage] = React.useState<number>(1);
    const getColumnCell = (i: T, c: ColumnDef<T>) => <div key={c.id} className={`table-data-cell ${c.classNamePrefix}-data-cell ${props.style?.(i)}`} >{c.cell(i)}</div>
    const iterateColumnDefsAndApply = (i: T) => {
        const cells = props.columnDefs.map(c => getColumnCell(i, c));
        return (
            <div key={props.keyGen(i)} className='table-row'>
                { cells }
            </div>
        );
    };
    const acceptsFilters = props.columnDefs.some(c => c.filterPredicate);
    const pageSize = props.pageSize || 25;
    const filteredItems = props.items.filter(i => !acceptsFilters || !filterText || props.columnDefs.some(c => c.filterPredicate?.(i, filterText || '')));
    const isFiltered = Boolean(filterText);
    const pageItems = pageSize ? (filteredItems.slice((selectedPage - 1) * pageSize, selectedPage * pageSize)) : filteredItems;
    const tableRows = pageItems.map(iterateColumnDefsAndApply);
    const extraRow = props.includeCreateRow ? (
        <div className='table-row'>
            { props.columnDefs.map(c => {
                return <div key={c.header} className={`table-data-cell table-create-cell ${c.classNamePrefix}-data-cell`}>{c.createRowCell?.()}</div>
            })}
        </div>
    ) : null;
    const tableHeaders = props.columnDefs.map(c => {
        return (
            <div key={c.header} className={`table-column-header-cell ${c.classNamePrefix}-column-header-cell`}>{c.header}</div>
        );
    });
    const loadingElement = (
        <div key='loading' className='loading'>Loading...</div>
    );


    // Make filter element if applicable
    const onFilter = (input) => {
        setFilterText(extractInputValue(input))
    };
    const filterElement = acceptsFilters
        ?
            <div className='table-filter-text-container'>
                <input type='text' className='table-filter-text-input' placeholder='Filter datapoints' value={filterText} onChange={onFilter} />
            </div>
        : null;

    // Make paginator element
    const size = props.pageSize || 25;
    const filteredLength = filteredItems.length;
    const unfilteredLength = props.items.length;
    const firstItem = (selectedPage - 1) * size + 1;
    if (filteredLength < firstItem && selectedPage > 1) {
        setSelectedPage(1);
    }
    const lastItem = Math.min(selectedPage * size, filteredLength);
    const numberPages = Math.max(Math.ceil(filteredLength / size), 1);
    const previousPage = Math.max(1, selectedPage - 1);
    const nextPage = Math.min(numberPages, selectedPage + 1);
    const displayFirst = previousPage !== selectedPage;
    const displayLast = nextPage !== selectedPage;
    //const displayPrevious = previousPage !== selectedPage;
    //const displayNext = nextPage !== selectedPage;
    let paginationInfoElement: JSX.Element | null = null;
    if (!props.hidePaginationInfo) {
        if (filteredLength === 0 && unfilteredLength > 0) {
            paginationInfoElement = <div className='paginator-page-info'>No matches for filter</div>
        } else if (unfilteredLength === 0) {
            paginationInfoElement = <div className='paginator-page-info'>No items</div>
        } else {
            paginationInfoElement = <div className='paginator-page-info'>Showing {firstItem} to {lastItem} of {filteredLength}{ isFiltered ? ' filtered' : null} entries { isFiltered ? `[${unfilteredLength} entries total]` : null }</div>
        }
    }
    const paginatorElement = (
        numberPages > 1
            ?
                <div className='paginator'>
                    { paginationInfoElement }
                    <div className='paginator-controls'>
                        <Button className='paginator-button' disabled={selectedPage === 1} onClick={() => setSelectedPage(selectedPage - 1)} >&lt;</Button>
                        { displayFirst ? <Button className='paginator-button paginator-page-number' onClick={() => setSelectedPage(1)} >1</Button> : null }
                        { displayFirst ? <div className='paginator-elipses'>{"..."}</div> : null}
                        {/* displayFirst ? <Button className='paginator-button paginator-page-number' onClick={() => setSelectedPage(1)} >1</Button> : null */}
                        {/* displayFirst && previousPage !== 2 ? <div className='paginator-elipses'>{"..."}</div> : null */}
                        {/* displayPrevious ? <Button className='paginator-button paginator-page-number' onClick={() => setSelectedPage(selectedPage - 1)} >{previousPage}</Button> : null */}
                        <div className='paginator-selected-page-number'>{ selectedPage }</div>
                        {/* displayNext ? <Button className='paginator-button paginator-page-number' onClick={() => setSelectedPage(selectedPage + 1)} >{nextPage}</Button> : null */}
                        {/* displayLast && nextPage !== (numberPages - 1) ? <div className='paginator-elipses'>{"..."}</div> : null*/}
                        {/* displayLast ? <Button className='paginator-button paginator-page-number' onClick={() => setSelectedPage(numberPages)} >{numberPages}</Button> : null */}
                        { displayLast ? <div className='paginator-elipses'>{"..."}</div> : null}
                        { displayLast ? <Button className='paginator-button paginator-page-number' onClick={() => setSelectedPage(numberPages)} >{numberPages}</Button> : null }
                        <Button className='paginator-button' disabled={selectedPage === numberPages} onClick={() => setSelectedPage(selectedPage + 1)} >&gt;</Button>
                    </div>
                </div>
            : 
                <div className='paginator'>
                    { paginationInfoElement }
                </div>

    );

    const delay = props.rowColorFadeDelay || COLOR_FADE_DELAY;
    const duration = props.rowColorFadeDuration || COLOR_FADE_DURATION;
    const style = { "--animDelay": `${delay}ms`, "--animDuration": `${duration}ms` } as React.CSSProperties;
    return (
        <div style={style} className="table-container">
            <div className='table-header'>{ props.header }</div>
            <div className="table-controls">
                { filterElement }
                { paginatorElement }
            </div>
            { props.loading ? loadingElement : 
                <div className='table'>
                    <div className='table-row'>
                        { tableHeaders }
                    </div>
                    { tableRows }
                    { extraRow }
                </div>
            }
        </div>
    );
};

export default Table;
