import React from "react";

interface ToggleButtonOptionData {
    value: string;
    callback: () => void;
}
interface ToggleButtonProps {
    className?: string;
    options: ToggleButtonOptionData[];
}

const ToggleButton = (props: ToggleButtonProps) => {
    const [selected, setSelected] = React.useState<string>(props.options?.[0].value || '');
    const ToggleButtonOption: React.FunctionComponent<ToggleButtonOptionData> = ({ value, callback, children }) => {
        const handleClick = () => {
            callback();
            setSelected(value);
        };
        return (
            <div key={value} className={`toggle-button-option ${selected === value ? 'toggle-button-selected' : null}`} onClick={handleClick} >{children}</div>
        );
    }
    const toggleButtonOptions = props.options.map(o => <ToggleButtonOption key={o.value} value={o.value} callback={o.callback} >{o.value}</ToggleButtonOption>);
    if (!props.options || !(props.options.length > 0)) {
        return <></>
    } else {
        return (
            <div className={`toggle ${props.className}`}>
                { toggleButtonOptions }
            </div>
        )
    };
}

export default ToggleButton;
