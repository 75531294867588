import React from "react";
import ToggleButton from "./ToggleButton";
import ClearFloat from './ClearFloat';

import './TabbedView.css';

interface View {
    title: string;
    label: string;
    view: JSX.Element;
}

interface TabbedViewProps {
    toggleButtonClassName?: string;
    views: View[];
}

const TabbedView: React.FunctionComponent<TabbedViewProps> = ({ toggleButtonClassName, views }) => {
    const [whichView, setWhichView] = React.useState<string>(views?.[0].title || '');
    const toggleViewHandler = (newWhichView: string) => {
        return () => {
            setWhichView(newWhichView);
        };
    }
    const toggleButtonOptions = views.map(v => { return { value: v.label, callback: toggleViewHandler(v.title) }; });
    const selectedView = views.find(v => v.title === whichView);
    return (
        <>
            <ToggleButton className={toggleButtonClassName} options={toggleButtonOptions} />
            <ClearFloat />
            <div className="tabbed-view-container">
                { selectedView?.view }
            </div>
        </>
    );
};

export default TabbedView;
