import Select, { GroupBase, Props, StylesConfig } from "react-select";

interface SelectProps<T> {
    className?: string;
    placeholder?: string;
    isMulti?: boolean;
    isClearable?: boolean;
    items: T[];
    isDisabled?: boolean;
    selectedValue?: any;
    selectedValues?: any[];
    option: (t: T) => { value: any, label: string };
    onChange?: (value: any) => void
}
const MySelect = <T,>(props: SelectProps<T>) => {
    let selectedOptions: any = null;
    const options = props.items.map(props.option);
    if (props.selectedValues) {
        selectedOptions = options.filter(o => props.selectedValues && props.selectedValues.some(s => s === o.value));
    } else if (props.selectedValue) {
        selectedOptions = options.find(o => o.value === props.selectedValue);
    }
    /*
    // Below is a theme; this is an alternate way of adjusting height, etc of react-select Select.
    // However, a more comprehensive approach is needed for a responsive Select, so theme was
    // abandoned.
    const customTheme = (theme: any) => ({
        ...theme,
        spacing: {
            ...theme.spacing,
            controlHeight: 20,
            baseUnit: 1
        }
    });*/
    const isMulti = props.isMulti || false;
    type IsMulti = typeof isMulti;
    const styles: StylesConfig<{ label: string, value: string}, IsMulti> = {
        control: (provided, state) => {
            return {
                ...provided,
                minHeight: '1.8rem',
                height: '1.8rem',
            }
        },
        valueContainer: (provided, state) => {
            return {
                ...provided,
                minHeight: '1.8rem',
                height: '1.8rem',
                padding: '0 0.3rem'
            }
        },
        input: (provided, state) => {
            return {
                ...provided,
                margin: '0'
            }
        },
        indicatorSeparator: (provided, state) => {
            return {
                ...provided,
            }
        },
        indicatorsContainer: (provided, state) => {
            return {
                ...provided,
            }
        },
        dropdownIndicator: (provided, state) => {
            return {
                ...provided,
                width: '1.5rem',
                minWidth: '1.5rem',
                height: '1.8rem',
                minHeight: '1.8rem',
                padding: '0',
                'svg': {
                    width: '1rem',
                    minWidth: '1rem',
                    height: '1rem',
                    minHeight: '1rem',
                    padding: '0.4rem 0.2rem 0 0.2rem',
                }
            }
        }
    };
    return (
        <div className={`${props.className}`}>
            <Select /*theme={customTheme}*/ styles={styles} isClearable={props.isClearable} isMulti={props.isMulti} closeMenuOnSelect={!props.isMulti} placeholder={props.placeholder}
                isDisabled={props.isDisabled} value={selectedOptions} onChange={props.onChange} options={options} />
        </div>
    );
}

export default MySelect;
