import { Source, SourceAttributes, EcuSourceAttributes } from "../model/Source";

export interface SourceRequestCreator<T extends SourceAttributes> {
    createUpdateRequest: (t: Source<T>) => any;
    createCreateRequest: (t: T) => any;
}

const mapToJson = (map: Map<string, any>) => {
    let jsonObj: any = {};
    map.forEach((value, key) => {
        jsonObj[key] = value;
    });
    return jsonObj;
}

export const ecuSourceRequestCreator: SourceRequestCreator<EcuSourceAttributes> = {
    createUpdateRequest: function (source: Source<EcuSourceAttributes>): any {
        const datapointTagJson = mapToJson(source.attributes.datapointTags);
        return {
            updated_attributes: {
                id: source.id,
                version: source.attributes.version || 'v1.0',
                name: source.attributes.name,
                description: source.attributes.description,
                vehicle_id: source.attributes.vehicleId,
                datapoint_tags: datapointTagJson
            }
        }
    },
    createCreateRequest: function (source: EcuSourceAttributes): any {
        const datapointTagJson = mapToJson(source.datapointTags);
        return {
            source_attributes: {
                version: source.version,
                name: source.name,
                description: source.description,
                vehicle_id: source.vehicleId,
                datapoint_tags: datapointTagJson
            }
        };
    }
};
