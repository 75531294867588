import FlashMessage from 'react-flash-message';

import { COLOR_FADE_DELAY, COLOR_FADE_DURATION } from '../common/Constants';

import './FlashMessage.css';

interface FlashMessageProps {
    className?: string;
    delay?: number;
    duration?: number;
    persistOnHover?: boolean;
}

const MyFlashMessage = (props: React.PropsWithChildren<FlashMessageProps>) => {
    const delay = props.delay || COLOR_FADE_DELAY;
    const duration = props.duration || COLOR_FADE_DURATION;
    const style = { "--animDelay": `${delay}ms`, "--animDuration": `${duration}ms` } as React.CSSProperties;
    return (
        <FlashMessage key={Math.random()+1} duration={delay + duration} persistOnHover={props.persistOnHover} >
            <div style={style} className={`flash-message ${props.className}`}>
                { props.children }
            </div>
        </FlashMessage>
    );
};

export const SuccessFlashMessage: React.FC<FlashMessageProps> = ( {className, delay, duration, persistOnHover, children} ) => {
    return (
        <MyFlashMessage className={`success-flash-message ${className}`} delay={delay || COLOR_FADE_DELAY} duration={duration || COLOR_FADE_DELAY} persistOnHover={persistOnHover} >{children}</MyFlashMessage>
    );
};

export const FailureFlashMessage: React.FC<FlashMessageProps> = ( {className, delay, duration, persistOnHover, children} ) => {
    return (
        <MyFlashMessage className={`failure-flash-message ${className}`} delay={delay || COLOR_FADE_DELAY} duration={duration || COLOR_FADE_DELAY} persistOnHover={persistOnHover} >{children}</MyFlashMessage>
    );
};

export default MyFlashMessage;
