import { AxiosInstance } from 'axios';
import { Datapoint, DatapointAttributes } from '../model/Datapoint';
import { DatapointRequestCreator } from './DatapointRequestCreator';
import { Marshaller } from './Marshaller';

export class DatapointClient {

    private service: AxiosInstance;
    constructor(axiosInstance: AxiosInstance) {
        this.service = axiosInstance;
    }

    public async getDatapoints<T extends DatapointAttributes>(marshaller: Marshaller<any, Datapoint<T>>, filter?: (i: any) => boolean): Promise<Datapoint<T>[]> {
        const result = await this.service.get('/configuration/datapoints');
        const dps = result.data.datapoints;
        const filteredKeys = (Object.keys(dps) as Array<string>).filter(k => {
            if (!filter) return true;
            return filter(dps[k]);
        });
        return filteredKeys.map(k => {
            const dp = dps[k];
            return marshaller.marshal(dp);
        }) as Datapoint<any>[];
    }

    public async updateDatapoint<T extends DatapointAttributes>(datapoint: Datapoint<T>, requestCreator: DatapointRequestCreator<T>, datapointMarshaller: Marshaller<any, Datapoint<T>>): Promise<Datapoint<any>> {
        const body = {
            ...requestCreator.createUpdateRequest(datapoint),
        };
        const result = await this.service.put(`/configuration/datapoints/${datapoint.id}`, body);
        if (result.data.statusCode != 200) {
            throw new Error(`Error updating datapoint with id: ${datapoint.id}; error message: ${result.data.message}`);
        } else {
            return datapointMarshaller.marshal(result.data.updated_datapoint);
        }
    }

    public async createDatapoint<T extends DatapointAttributes>(datapoint: T, requestCreator: DatapointRequestCreator<T>, datapointMarshaller: Marshaller<any, Datapoint<T>>): Promise<Datapoint<any>> {
        const body = {
            ...requestCreator.createCreateRequest(datapoint),
        };
        const result = await this.service.post('/configuration/datapoints', body);
        if (result.data.statusCode != 200) {
            throw new Error(`Error creating datapoint; error message: ${result.data.message}`);
        }
        const dp = datapointMarshaller.marshal(result.data.created_datapoint);
        return dp;
    }

    public async deleteDatapoint(datapointId: string): Promise<boolean> {
        const result = await this.service.delete(`/configuration/datapoints/${datapointId}`);
        console.log(result.data.statusCode);
        if (result.data.statusCode != 200) {
            throw new Error(`Error deleting datapoint with id: ${datapointId}; error message: ${result.data.message}`);
        } else {
            return new Promise<boolean>((resolve, reject) => {
                resolve(true);
            });
        }
    }

}
