import React from 'react';
import { useLocation } from 'react-router-dom';

import Header from './Header';
import { SideNav } from './SideNav'
import { FlashMessagesContext } from './common/FlashMessagesContext';

import './AppLayout.css'

interface AppLayoutProps {
    content: React.ReactNode;
}


const AppLayout = ( {content} ) => {
    const [flashMessages, setFlashMessages] = React.useState<JSX.Element[]>([]);
    const createSideNavItem = (label: string, linkRef: string) => {
        return ({ label, linkRef });
    }
    const titlePathMap = new Map<string, string>([ ['/', 'Subscriptions'], ['/subscribers', 'Subscribers'], ['/sources', 'Sources'], ['/datapoints', 'Datapoints'], ['/download', 'Download'] ]);
    const sideNavItems = [ createSideNavItem('Subscriptions', '/'), createSideNavItem('Subscribers', '/subscribers'), createSideNavItem('Sources', '/sources'), createSideNavItem('Datapoints', '/datapoints'), createSideNavItem('Download', '/download') ];
    //const titlePathMap = new Map<string, string>([['/', 'Dashboard'], ['/subscriptions', 'Subscriptions'], ['/usermgmt', 'User Management'], ['/replay', 'Replay'], ['/feedmgmt', 'Feed Management']]);
    //const sideNavItems = [ createSideNavItem('Dashboard', '/'),*/ createSideNavItem('Subscriptions', '/subscriptions'),
    //    /*createSideNavItem('User Management', '/usermgmt'), createSideNavItem('Replay', '/replay'), createSideNavItem('Feed Management', '/feedmgmt')];
    return (
        <div>
            <Header currentLocation={titlePathMap.get(useLocation().pathname)}></Header>
            <SideNav items={sideNavItems} />
            <div className='content'>
                <FlashMessagesContext.Provider value={{flashMessages: [], addFlashMessage: (flashMessage: JSX.Element) => {
                    flashMessages.push(flashMessage);
                    setFlashMessages([...flashMessages]);
                }}}>
                    <div>
                        { flashMessages }
                    </div>
                    { content }
                </FlashMessagesContext.Provider>
            </div>
        </div>
    );
};

export default AppLayout;
