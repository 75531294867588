import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AxiosInstanceContextInterface, withAxiosInstance } from './common/AxiosInstanceContext';
import { ConfigurationClient } from './common/ConfigurationClient';
import { DatapointClient } from './common/DatapointClient';
import { DownloadClient } from './common/DownloadClient';
import { SourceClient } from './common/SourceClient';
import { SubscriberClient } from './common/SubscriberClient';
import { SubscriptionClient } from './common/SubscriptionClient';
import { UserClient } from './common/UserClient';
import DashboardView from './DashboardView';
import DatapointView from './DatapointView';
import SubscriptionView from './SubscriptionView';
import SubscriberView from './SubscribersView';
import DownloadView from './DownloadView';
import SourceView from './SourceView';

const AppRoutes: React.FC<AxiosInstanceContextInterface> = ({ axiosInstance }) => {

    const downloadClient = new DownloadClient(axiosInstance);
    const datapointClient = new DatapointClient(axiosInstance);
    const sourcesClient = new SourceClient(axiosInstance);
    const subscriberClient = new SubscriberClient(axiosInstance);
    const subscriptionClient = new SubscriptionClient(axiosInstance);
    const userClient = new UserClient(axiosInstance);
    const configurationClient = new ConfigurationClient(datapointClient, sourcesClient, subscriberClient, subscriptionClient, userClient);

    return (
        <Routes>
            <Route path='/' element={<SubscriptionView configurationClient={configurationClient} />} />
            <Route path='/datapoints' element={<DatapointView configurationClient={configurationClient} />} />
            <Route path='/subscriptions' element={<SubscriptionView configurationClient={configurationClient} />} />
            <Route path='/subscribers' element={<SubscriberView configurationClient={configurationClient} />} />
            <Route path='/sources' element={<SourceView configurationClient={configurationClient} />} />
            <Route path='/replay' element={<DashboardView />} />
            <Route path='/download' element={<DownloadView configurationClient={configurationClient} downloadClient={downloadClient} />} />
            <Route path='/feedmgmt' element={<DashboardView />} />
        </Routes>
    );
    //<Route path='/' element={<DashboardView />} />

};

export default withAxiosInstance(AppRoutes);
