import { AxiosInstance } from "axios";
import React from "react";

export interface AxiosInstanceContextInterface {
    axiosInstance: AxiosInstance;
}

export const AxiosInstanceContext = React.createContext<AxiosInstanceContextInterface>({
    axiosInstance: {} as AxiosInstance,
});

export const withAxiosInstance = (Component: any) => {
    return (props: any) => {
        return (
            <AxiosInstanceContext.Consumer>
                { ctx => <Component {...props} axiosInstance={ctx.axiosInstance} /> }
            </AxiosInstanceContext.Consumer>
        )
    }
};
