import { HealthCard, HealthStatus } from './components/HealthCard';
import { withAuth, AuthContextInterface } from './auth/AuthContext';

import './DashboardView.css';
import TitledView from './components/TitledView';

const DashboardView = (props: AuthContextInterface) => {
    return (
        <div className='home-page'>
            <TitledView title={'Health'} >
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="33%">
                                <HealthCard healthStatus={HealthStatus.GOOD} label={'Primary Data Feed Health - Online/Offline'}></HealthCard>
                            </td>
                            <td width="33%">
                                <HealthCard healthStatus={HealthStatus.GOOD} label={'Secondary Data Feed Health - Online/Offline'}></HealthCard>
                            </td>
                            <td width="33%">
                                <HealthCard healthStatus={HealthStatus.GOOD} label={'Archive Data Feed Health - Online/Offline'}></HealthCard>
                            </td>
                        </tr>
                        <tr>
                            <td width="33%">
                                <HealthCard healthStatus={HealthStatus.GOOD} label={'Active Subscribers Cloud'}></HealthCard>
                            </td>
                            <td width="33%">
                                <HealthCard healthStatus={HealthStatus.GOOD} label={'Active User Count'}></HealthCard>
                            </td>
                            <td width="33%">
                                <HealthCard healthStatus={HealthStatus.GOOD} label={'Active Race Count'}></HealthCard>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </TitledView>
            <TitledView title='Services'>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="33%">
                                <HealthCard healthStatus={HealthStatus.GOOD} label={'Input Service Status'}></HealthCard>
                            </td>
                            <td width="33%">
                                <HealthCard healthStatus={HealthStatus.GOOD} label={'Upload Service Status'}></HealthCard>
                            </td>
                            <td width="33%">
                                <HealthCard healthStatus={HealthStatus.GOOD} label={'NAT Service Status'}></HealthCard>
                            </td>
                        </tr>
                    
                        <tr>
                            <td width="33%">
                                <HealthCard healthStatus={HealthStatus.GOOD} label={'Config Service Status'}></HealthCard>
                            </td>
                            <td width="33%">
                                <HealthCard healthStatus={HealthStatus.GOOD} label={'Activation Service Status'}></HealthCard>
                            </td>
                            <td width="33%">
                                <HealthCard healthStatus={HealthStatus.GOOD} label={'k3s Status'}></HealthCard>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </TitledView>
        </div>
    );
};

export default withAuth(DashboardView);
