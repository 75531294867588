
interface BadgeProps {
    className?: string;
}

const Badge: React.FunctionComponent<BadgeProps> = ({ className, children }) => {
    return (
        <div className={`badge ${className}`}>{children}</div>
    );
}

export default Badge;
