import { Datapoint, DatapointAttributes, EcuDatapointAttributes } from "../model/Datapoint";

export interface DatapointRequestCreator<T extends DatapointAttributes> {
    createUpdateRequest: (t: Datapoint<T>) => any;
    createCreateRequest: (t: T) => any;
}

export const ecuDatapointRequestCreator: DatapointRequestCreator<EcuDatapointAttributes> = {
    createUpdateRequest: function (datapoint: Datapoint<EcuDatapointAttributes>): any {
        return {
            datapoint_type: 'ECU',
            updated_attributes: {
                id: datapoint.id,
                version: datapoint.attributes.version || 'v1.0',
                name: datapoint.attributes.name,
                description: datapoint.attributes.description,
                rate: datapoint.attributes.rate,
                range_min: datapoint.attributes.rangeMin,
                range_max: datapoint.attributes.rangeMax,
                units: datapoint.attributes.units,
                can_id: datapoint.attributes.canId,
                transmission_method: datapoint.attributes.transmissionMethod,
                ecu: datapoint.attributes.ecu,
                msb: datapoint.attributes.msb,
                length: datapoint.attributes.length,
                gain: datapoint.attributes.gain,
            }
        }
    },
    createCreateRequest: function (dp: EcuDatapointAttributes): any {
        return {
            datapoint_type: 'ECU',
            datapoint_attributes: {
                version: dp.version,
                name: dp.name,
                description: dp.description,
                rate: dp.rate,
                range_min: dp.rangeMin,
                range_max: dp.rangeMax,
                units: dp.units,
                can_id: dp.canId,
                transmission_method: dp.transmissionMethod,
                ecu: dp.ecu,
                msb: dp.msb,
                length: dp.length,
                gain: dp.gain,
            }
        };
    }
};
