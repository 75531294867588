import ClearFloat from './components/ClearFloat';
import './Header.css';
import React from 'react';
import { Auth, Hub } from 'aws-amplify';




interface HeaderProps {
    currentLocation?: string;
}

const handleSignOutButtonClick = async () => {
    try {
        await Auth.signOut();
        Hub.dispatch('UI Auth', {   // channel must be 'UI Auth'
            event: 'AuthStateChange',    // event must be 'AuthStateChange'
            message: 'signedout'    // message must be 'signedout'
        });
    } catch (error) {
        console.log('error signing out: ', error);
    }
};

const CustomSignOutButton = () => {
    return (
        <button className="header-right logoutButton"
            onClick={handleSignOutButtonClick}>
            <span>LOGOUT</span>
        </button>
    );
};


const Header = (props: HeaderProps) => {
    return (
        <div className="header">
            <img src={require('./resources/NASCAR_Circle_R_FullColor_onBlack_RGB.png')} className='logo' />
            <div className="header-left">
                <span className="current-location">{props.currentLocation}</span>
            </div>
            <CustomSignOutButton/>
            <ClearFloat />
        </div>
    );
};

export default Header;
