import React from "react";

export interface FlashMessagesConsumer {
    flashMessages: JSX.Element[];
}

export interface FlashMessagesProducer {
    addFlashMessage: (flashMessage: JSX.Element) => void;
}

export type FlashMessagesContextInterface = FlashMessagesProducer & FlashMessagesConsumer;

export const FlashMessagesContext = React.createContext<FlashMessagesContextInterface>({
    flashMessages: [] as JSX.Element[],
    addFlashMessage: (flashMessage: JSX.Element) => console.log(flashMessage)
});

export const withFlashMessages = (Component: any) => {
    return (props: any) => {
        return (
            <FlashMessagesContext.Consumer>
                { ctx => <Component {...props} flashMessages={ctx.flashMessages} addFlashMessage={ctx.addFlashMessage} /> }
            </FlashMessagesContext.Consumer>
        )
    }
};
