import { AxiosInstance } from 'axios';

export class DownloadClient {

    private service: AxiosInstance;
    constructor(axiosInstance: AxiosInstance) {
        this.service = axiosInstance;
    }

    public async generateManifestFile(subscriberId: string, startTime: string, endTime: string): Promise<string> {
        const body = {
            subscriber_id: subscriberId,
            start_time: startTime,
            end_time: endTime
        };
        const result = await this.service.post('/download', body);
        return result.data.presigned_url as string;
    }

}
