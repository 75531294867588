import { AxiosInstance } from "axios";
import { NewSubscription, Subscription } from "../model/Subscription";
import pako from 'pako';

export class SubscriptionClient {



    private marshalResponse(i: any): Subscription {
        return {
            id: i.id,
            version: i.version,
            name: i.name,
            description: i.description,
            enabled: i.enabled,
            datapointId: i.datapoint_id,
            sourceId: i.source_id,
            subscriberId: i.subscriber_id,
        }
    }

    private createUpdateRequest(subscription: Subscription): any {
        return {
            updated_attributes: {
                id: subscription.id,
                version: 'v1.0',
                name: subscription.name,
                description: subscription.description,
                enabled: subscription.enabled,
                datapoint_id: subscription.datapointId,
                source_id: subscription.sourceId,
                subscriber_id: subscription.subscriberId
            }
        };
    }

    private createCreateRequest(subscription: NewSubscription): any {
        return {
            subscription_attributes: {
                version: 'v1.0',
                name: subscription.name,
                description: subscription.description,
                enabled: subscription.enabled,
                datapoint_id: subscription.datapointId,
                source_id: subscription.sourceId,
                subscriber_id: subscription.subscriberId
            }
        };
    }

    private service: AxiosInstance;
    constructor(service: AxiosInstance) {
        this.service = service;
    }

    private decompress(data) {
        const binaryStr = atob(data);
        const binLen = binaryStr.length;

        const compressedData = new Uint8Array(binLen);

        for (let i = 0; i < binLen; i++) {
            compressedData[i] = binaryStr.charCodeAt(i);
        }

        const decomp = pako.inflate(compressedData, {to: 'string'});

        return JSON.parse(decomp);
    }

    public async getSubscriptions(filter?: (i: any) => boolean) {
        try {
            const result = await this.service.get('/configuration/subscriptions');
            const subscriptions = this.decompress(result.data.body).subscriptions;

            const filteredKeys = (Object.keys(subscriptions) as Array<string>).filter(k => {
                if (!filter) return true;
                return filter(subscriptions[k]);
            });
            return filteredKeys.map(k => {
                const subscription = subscriptions[k];
                return this.marshalResponse(subscription);
            }) as Subscription[];
        } catch (err) {
            console.log(err)
        }
    }

    public async createSubscription(newSubscription: NewSubscription): Promise<Subscription> {
        const body = {
            ...this.createCreateRequest(newSubscription),
        };
        const result = await this.service.post('/configuration/subscriptions', body);
        if (result.data.statusCode != 200) {
            throw new Error(`Error creating subscription; error message: ${result.data.message}`);
        }
        const s = this.marshalResponse(result.data.created_subscription);
        return s;
    }

    public async updateSubscription(subscription: Subscription): Promise<Subscription> {
        const body = {
            ...this.createUpdateRequest(subscription),
        };
        const result = await this.service.put(`/configuration/subscriptions/${subscription.id}`, body);
        if (result.data.statusCode != 200) {
            throw new Error(`Error updating subscription with id: ${subscription.id}; error message: ${result.data.message}`);
        } else {
            return this.marshalResponse(result.data.updated_subscription);
        }
    }

    public async deleteSubscription(id: string): Promise<boolean> {
        const result = await this.service.delete(`/configuration/subscriptions/${id}`);
        console.log(result.data.statusCode);
        if (result.data.statusCode != 200) {
            throw new Error(`Error deleting subscription with id: ${id}; error message: ${result.data.message}`);
        } else {
            return new Promise<boolean>((resolve, reject) => resolve(true));
        }
    }
}
