import { AxiosInstance } from "axios";

export class UserClient {

    private service: AxiosInstance;
    constructor(axiosInstance: AxiosInstance) {
        this.service = axiosInstance;
    }

    getUsers() {

    }
}
