
interface ButtonProps {
    className?: string;
    disabled?: boolean;
    onClick?: () => void;
}

const Button: React.FunctionComponent<ButtonProps> = ({ className, disabled, onClick, children }) => {
    return disabled
        ? <div className={`button disabled ${className}`} >{children}</div>
        : <div className={`button ${className}`} onClick={onClick}>{children}</div>;
}

export default Button;
