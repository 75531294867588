import React from 'react';
import { useNavigate } from 'react-router-dom';

import './SideNav.css';

interface SideNavProps {
    items: ({ label: string, linkRef: string })[],
}

interface SideNavItemProps {
    label: string,
    linkRef: string,
    initiallySelected: string,
}


export const SideNav = (props: SideNavProps) => {
    const items = props.items;
    const navigate = useNavigate();
    const [pathToSelectedMap, setPathToSelectedMap] = React.useState<Map<string, string>>(new Map<string, string>(items.map(i => {
        return [i.linkRef, i.label];
    })));
    const selected = pathToSelectedMap.get(window.location.pathname);

    const SideNavItem = (props: SideNavItemProps) => {
        const handleClick = () => {
            navigate(props.linkRef);
        };
        return (
            <div key={props.label} className={`sidenav-item ${selected === props.label ? 'selected' : null}`} onClick={handleClick} >{props.label}</div>
        );
    }
    const itemElements = items.map(i => {
        return SideNavItem({ label: i.label, linkRef: i.linkRef, initiallySelected: 'Home' });
    });
    return (
        <div className='sidenav'>
            { itemElements }
        </div>
    );
};
