import { AxiosInstance } from 'axios';
import Subscriber, { NewSubscriber } from '../model/Subscriber';

export class SubscriberClient {

    private marshalResponse(i: any): Subscriber {
        return {
            version: i.version,
            id: i.id,
            name: i.name,
            description: i.description,
            nats: {
                primaryAccount: i.nats.primary_account,
                accountName: i.nats.account_name,
                userName: i.nats.user_name
            },
            access: {
                iamRoleArn: i.access.iam_role_arn,
                s3Bucket: i.access.s3_bucket,
                replayEnabled: i.access.replay_enabled,
                replayLimits: i.access.replay_limits,
                downloadEnabled: i.access.download_enabled,
                downloadLimits: i.access.download_limits,
                liveStreamEnabled: i.access.live_stream_enabled
            }
        }
    }
    
    private service: AxiosInstance;
    constructor(service: AxiosInstance) {
        this.service = service;
    }

    public async getSubscribers(filter?: (i: any) => boolean): Promise<Subscriber[]> {
        const result = await this.service.get('/configuration/subscribers');
        const subscribers = result.data.subscribers;
        const filteredKeys = (Object.keys(subscribers) as Array<string>).filter(k => {
            if (!filter) return true;
            return filter(subscribers[k]);
        });
        return filteredKeys.map(k => {
            const subscriber = subscribers[k];
            return this.marshalResponse(subscriber);
        }) as Subscriber[];
    }

    public async createSubscriber(newSubscriber: NewSubscriber): Promise<Subscriber> {
        const body = {
            subscriber_attributes: {
                version: 'v1.0',
                name: newSubscriber.name,
                description: newSubscriber.description,
                access: {
                    download_enabled: newSubscriber.access.downloadEnabled,
                    download_limits: newSubscriber.access.downloadLimits,
                    iam_role_arn: newSubscriber.access.iamRoleArn,
                    live_stream_enabled: newSubscriber.access.liveStreamEnabled,
                    replay_enabled: newSubscriber.access.replayEnabled,
                    replay_limits: newSubscriber.access.replayLimits,
                    s3_bucket: newSubscriber.access.s3Bucket
                },
                nats: {
                    account_name: newSubscriber.nats.accountName,
                    primary_account: newSubscriber.nats.primaryAccount,
                    user_name: newSubscriber.nats.userName
                }
            }
        };
        const result = await this.service.post('/configuration/subscribers', body);
        if (result.data.statusCode != 200) {
            throw new Error(`Error creating subscriber; error message: ${result.data.message}`);
        } else {
            return this.marshalResponse(result.data.created_subscriber);
        }
    }

    public async updateSubscriber(subscriber: Subscriber): Promise<Subscriber> {
        const body = {
            updated_attributes: {
                id: subscriber.id,
                version: 'v1.0',
                name: subscriber.name,
                description: subscriber.description,
                access: {
                    download_enabled: subscriber.access.downloadEnabled,
                    download_limits: subscriber.access.downloadLimits,
                    iam_role_arn: subscriber.access.iamRoleArn,
                    live_stream_enabled: subscriber.access.liveStreamEnabled,
                    replay_enabled: subscriber.access.replayEnabled,
                    replay_limits: subscriber.access.replayLimits,
                    s3_bucket: subscriber.access.s3Bucket
                },
                nats: {
                    account_name: subscriber.nats.accountName,
                    primary_account: subscriber.nats.primaryAccount,
                    user_name: subscriber.nats.userName
                }
            }
        };
        const result = await this.service.put(`/configuration/subscribers/${subscriber.id}`, body);
        if (result.data.statusCode != 200) {
            throw new Error(`Error updating subscriber with id: ${subscriber.id}; error message: ${result.data.message}`);
        } else {
            return this.marshalResponse(result.data.updated_subscriber);
        }
    }

    public async deleteSubscriber(subscriberId: string): Promise<boolean> { 
        const result = await this.service.delete(`/configuration/subscribers/${subscriberId}`);
        console.log(result.data.statusCode);
        if (result.data.statusCode != 200) {
            throw new Error(`Error deleting subscriber with id: ${subscriberId}; error message: ${result.data.message}`);
        } else {
            return new Promise<boolean>((resolve, reject) => {
                resolve(true);
            });
        }
    }

}
